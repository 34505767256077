import React, { useEffect, useState } from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Cookies from 'js-cookie'
import SelectWithImages from '../../../../components/UI/SelectWithImages'
import { connect } from 'react-redux'

const MobileFilters = ({
  applyFilters,
  resetFilters,
  dcManagement,
  coinsList,
  poolsList,
  walletsList,
  presetsList,
  minersList,
}) => {
  const [show, setShow] = useState(false)
  const toggle = () => {
    setShow(!show)
  }
  const { t } = useTranslation('common')
  const parseJson = json => {
    try {
      return JSON.parse(json)
    } catch (e) {
      return ''
    }
  }
  const groupOptions = [{ value: 'all', label: t('pages.workers.all') }]
  const [dcOptions, setDcOptions] = useState([])
  useEffect(() => {
    const optionsArray = [{ value: 'all', label: t('pages.workers.all') }]
    dcManagement.dataCentersList.forEach(dc => {
      optionsArray.push({ value: dc.id, label: dc.name })
      dc.rooms &&
        dc.rooms.forEach(room => {
          optionsArray.push({
            value: 'room' + room.id,
            label: '  |---' + room.name,
          })
        })
    })
    setDcOptions(optionsArray)
  }, [dcManagement.dataCentersList])

  const [dc, setDc] = useState({ value: 'all', label: t('pages.workers.all') })
  const [coin, setCoin] = useState(null)
  const [wallet, setWallet] = useState(null)
  const [preset, setPreset] = useState(null)
  const [pool, setPool] = useState(null)
  const [miner, setMiner] = useState(null)
  const [minHashrate, setMinHashrate] = useState('')
  const [maxHashrate, setMaxHashrate] = useState('')
  const [minTemp, setMinTemp] = useState('')
  const [maxTemp, setMaxTemp] = useState('')
  const [lowHashrate, setLowHashrate] = useState(false)
  const [rejectedShares, setRejectedShares] = useState(false)
  const [stopError, setStopError] = useState(false)

  const apply = () => {
    const filters = {
      dc,
      coin,
      wallet,
      preset,
      pool,
      miner,
      minHashrate,
      maxHashrate,
      minTemp,
      maxTemp,
      lowHashrate,
      rejectedShares,
      stopError,
    }
    applyFilters(filters)
    toggle()
  }
  useEffect(() => {
    setDc(
      Cookies.get(window.location.pathname + 'rigDC')
        ? parseJson(Cookies.get(window.location.pathname + 'rigDC'))
        : { value: 'all', label: t('pages.workers.all') },
    )

    setCoin(
      Cookies.get(window.location.pathname + 'rigCoin')
        ? parseJson(Cookies.get(window.location.pathname + 'rigCoin'))
        : null,
    )
    setWallet(
      Cookies.get(window.location.pathname + 'rigWallet')
        ? parseJson(Cookies.get(window.location.pathname + 'rigWallet'))
        : null,
    )
    setPreset(
      Cookies.get(window.location.pathname + 'rigPreset')
        ? parseJson(Cookies.get(window.location.pathname + 'rigPreset'))
        : null,
    )
    setPool(
      Cookies.get(window.location.pathname + 'rigPool')
        ? parseJson(Cookies.get(window.location.pathname + 'rigPool'))
        : null,
    )
    setMiner(
      Cookies.get(window.location.pathname + 'rigMiner')
        ? parseJson(Cookies.get(window.location.pathname + 'rigMiner'))
        : null,
    )
    setMinHashrate(
      Cookies.get(window.location.pathname + 'minHashrate')
        ? Cookies.get(window.location.pathname + 'minHashrate')
        : '',
    )
    setMaxHashrate(
      Cookies.get(window.location.pathname + 'maxHashrate')
        ? Cookies.get(window.location.pathname + 'maxHashrate')
        : '',
    )
    setMinTemp(
      Cookies.get(window.location.pathname + 'minTemp') ? Cookies.get(window.location.pathname + 'minTemp') : '',
    )
    setMaxTemp(
      Cookies.get(window.location.pathname + 'maxTemp') ? Cookies.get(window.location.pathname + 'maxTemp') : '',
    )
    setLowHashrate(
      Cookies.get(window.location.pathname + 'lowHashrate')
        ? Cookies.get(window.location.pathname + 'lowHashrate') === 'true'
        : false,
    )
    setRejectedShares(
      Cookies.get(window.location.pathname + 'rejectedShares')
        ? Cookies.get(window.location.pathname + 'rejectedShares') === 'true'
        : false,
    )
    setStopError(
      Cookies.get(window.location.pathname + 'stopError')
        ? Cookies.get(window.location.pathname + 'stopError') === 'true'
        : false,
    )
  }, [show])

  const onChangeCoin = option => {
    setCoin(option)
    Cookies.set(window.location.pathname + 'rigCoin', JSON.stringify(option), {
      expires: 30,
      sameSite: 'Lax',
      secure: true,
    })
  }

  return (
    <div
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <i onClick={toggle} className='fas fa-filter'></i>
      <Modal isOpen={show} toggle={toggle} className={'modal-dialog-centered'}>
        <ModalHeader tag={'h4'} toggle={toggle}>
          {t('pages.workers.applyFilters')}
        </ModalHeader>
        <ModalBody>
          <Row className={'workers_filters__elements'}>
            <Col sm={4} xs={6} className={'workers_filters__element'}>
              <label>{t('pages.workers.dataCentersFilter')}</label>
              <Select
                placeholder={t('pages.workers.all')}
                classNamePrefix={'form_control_react_select'}
                value={dc}
                onChange={option => {
                  setDc(option)
                  Cookies.set(window.location.pathname + 'rigDC', JSON.stringify(option), {
                    expires: 30,
                    sameSite: 'Lax',
                    secure: true,
                  })
                }}
                options={dcOptions}
              />
            </Col>
            <Col sm={4} xs={6} className={'workers_filters__element'}>
              <label>{t('pages.workers.workerGroup')}</label>
              <Select
                placeholder={t('pages.workers.all')}
                classNamePrefix={'form_control_react_select'}
                options={groupOptions}
              />
            </Col>
            <Col sm={4} xs={6} className={'workers_filters__element'}>
              <label>{t('pages.workers.coin')}</label>
              <SelectWithImages
                classNamePrefix={'form_control_react_select'}
                value={coin}
                onchange={onChangeCoin}
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                options={coinsList.map(coin => {
                  return {
                    ...coin,
                    icon: coin.nameSymbol,
                    label: coin.nameSymbol,
                    value: coin.nameSymbol,
                  }
                })}
                placeholder={t('pages.workers.all')}
              />
            </Col>
            <Col sm={4} xs={6} className={'workers_filters__element'}>
              <label>{t('pages.workers.wallet')}</label>
              <Select
                classNamePrefix={'form_control_react_select'}
                value={wallet}
                onChange={option => {
                  setWallet(option)
                  Cookies.set(window.location.pathname + 'rigWallet', JSON.stringify(option), {
                    expires: 30,
                    sameSite: 'Lax',
                    secure: true,
                  })
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                options={walletsList.map(wallet => {
                  return {
                    value: wallet.address,
                    label: wallet.name,
                  }
                })}
                placeholder={t('pages.workers.all')}
              />
            </Col>
            <Col sm={4} xs={6} className={'workers_filters__element'}>
              <label>{t('pages.workers.miner')}</label>
              <Select
                classNamePrefix={'form_control_react_select'}
                value={miner}
                onChange={option => {
                  setMiner(option)
                  Cookies.set(window.location.pathname + 'rigMiner', JSON.stringify(option), {
                    expires: 30,
                    sameSite: 'Lax',
                    secure: true,
                  })
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                options={minersList.map(miner => {
                  return {
                    value: miner.name,
                    label: miner.name,
                  }
                })}
                placeholder={t('pages.workers.all')}
              />
            </Col>
            <Col sm={4} xs={6} className={'workers_filters__element'}>
              <label>{t('pages.workers.pool')}</label>
              <Select
                classNamePrefix={'form_control_react_select'}
                value={pool}
                onChange={option => {
                  setPool(option)
                  Cookies.set(window.location.pathname + 'rigPool', JSON.stringify(option), {
                    expires: 30,
                    sameSite: 'Lax',
                    secure: true,
                  })
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                options={poolsList.map(pool => {
                  return {
                    value: pool.id,
                    label: pool.title,
                  }
                })}
                placeholder={t('pages.workers.all')}
              />
            </Col>
            <Col sm={4} xs={6} className={'workers_filters__element'}>
              <label>{t('pages.workers.miningPreset')}</label>
              <Select
                classNamePrefix={'form_control_react_select'}
                value={preset}
                onChange={option => {
                  setPreset(option)
                  Cookies.set(window.location.pathname + 'rigPreset', JSON.stringify(option), {
                    expires: 30,
                    sameSite: 'Lax',
                    secure: true,
                  })
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                options={presetsList.map(preset => {
                  return {
                    value: preset.id,
                    label: preset.name,
                  }
                })}
                placeholder={t('pages.workers.all')}
              />
            </Col>
            <Col sm={12} md={6} className={'workers_filters__element'}>
              <label>{t('pages.workers.troubleshooting')}</label>
              <div className={'workers_filters__buttons'}>
                <Button
                  color={lowHashrate ? 'primary' : 'secondary'}
                  onClick={() => {
                    setLowHashrate(!lowHashrate)
                    Cookies.set(window.location.pathname + 'lowHashrate', !lowHashrate, {
                      expires: 30,
                      sameSite: 'Lax',
                      secure: true,
                    })
                  }}
                >
                  <i className='far fa-chart-line-down'></i>
                  {t('pages.workers.lowHashrate')}
                </Button>
                <Button
                  color={rejectedShares ? 'primary' : 'secondary'}
                  onClick={() => {
                    setRejectedShares(!rejectedShares)
                    Cookies.set(window.location.pathname + 'rejectedShares', !rejectedShares, {
                      expires: 30,
                      sameSite: 'Lax',
                      secure: true,
                    })
                  }}
                >
                  <i className='far fa-ban' style={{ color: 'red' }}></i>
                  {t('pages.workers.rejectedShares')}
                </Button>
                <Button
                  color={stopError ? 'primary' : 'secondary'}
                  onClick={() => {
                    setStopError(!stopError)
                    Cookies.set(window.location.pathname + 'stopError', !stopError, {
                      expires: 30,
                      sameSite: 'Lax',
                      secure: true,
                    })
                  }}
                >
                  <i className='far fa-pause-circle' style={{ color: 'red' }}></i>
                  {t('pages.workers.stopError')}
                </Button>
              </div>
            </Col>
            <Col md={4} sm={12} className={'workers_filters__element'}>
              <Row className={'align-center'}>
                <Col xs={12} sm={4}>
                  {t('pages.workers.hashrateM')}
                </Col>
                <Col xs={12} sm={6} className={'align-center'}>
                  {t('pages.workers.min')}:
                  <Input
                    value={minHashrate}
                    onChange={e => {
                      const re = /^[+-]?\d*(?:[.,]\d*)?$/
                      if (e.target.value === '' || re.test(e.target.value)) {
                        //console.log(e.target.value)
                        setMinHashrate(e.target.value)
                        Cookies.set(window.location.pathname + 'minHashrate', e.target.value, {
                          expires: 30,
                          sameSite: 'Lax',
                          secure: true,
                        })
                      }
                    }}
                  />
                  &nbsp;&nbsp;{t('pages.workers.max')}:
                  <Input
                    value={maxHashrate}
                    onChange={e => {
                      const re = /^[+-]?\d*(?:[.,]\d*)?$/
                      if (e.target.value === '' || re.test(e.target.value)) {
                        //console.log(e.target.value)
                        setMaxHashrate(e.target.value)
                        Cookies.set(window.location.pathname + 'maxHashrate', e.target.value, {
                          expires: 30,
                          sameSite: 'Lax',
                          secure: true,
                        })
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={4} sm={12} className={'workers_filters__element'}>
              <Row className={'align-center'}>
                <Col xs={12} sm={4}>
                  {t('pages.workers.temp')}
                </Col>
                <Col xs={12} sm={6} className={'align-center'}>
                  {t('pages.workers.min')}:
                  <Input
                    value={minTemp}
                    onChange={e => {
                      const re = /^[+-]?\d*(?:[.,]\d*)?$/
                      if (e.target.value === '' || re.test(e.target.value)) {
                        //console.log(e.target.value)
                        setMinTemp(e.target.value)
                        Cookies.set(window.location.pathname + 'minTemp', e.target.value, {
                          expires: 30,
                          sameSite: 'Lax',
                          secure: true,
                        })
                      }
                    }}
                  />
                  &nbsp;&nbsp;{t('pages.workers.max')}:
                  <Input
                    value={maxTemp}
                    onChange={e => {
                      const re = /^[+-]?\d*(?:[.,]\d*)?$/
                      if (e.target.value === '' || re.test(e.target.value)) {
                        //console.log(e.target.value)
                        setMaxTemp(e.target.value)
                        Cookies.set(window.location.pathname + 'maxTemp', e.target.value, {
                          expires: 30,
                          sameSite: 'Lax',
                          secure: true,
                        })
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color='secondary'
            onClick={() => {
              resetFilters()
              toggle()
            }}
          >
            {t('pages.workers.reset')}
          </Button>
          <Button color='secondary' onClick={toggle}>
            {t('pages.workers.cancel')}
          </Button>
          <Button color='primary' onClick={apply}>
            {t('pages.workers.apply')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = store => ({
  rigsList: store.rigs.rigsList,
  delayDataUpdate: store.rigs.delayDataUpdate,
  coinsList: store.presets.coinsList,
  poolsList: store.presets.poolsList,
  walletsList: store.presets.walletsList,
  presetsList: store.presets.miningPresetList,
  minersList: store.presets.minersList,
  dcManagement: store.dcManagement,
  rigsStat: store.rigs.rigsStat,
})

export default connect(mapStateToProps, null)(MobileFilters)
