import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  Label,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import UiRangeSlider from '../../../../components/UI/RangeSlider'
import RangeSliderSpecial from '../../../../components/UI/RangeSliderSpecial'
import * as actions from '../../../RigsData/actions'
import * as actionsProfiles from '../../../OverclockingProfiles/actions'
import Select, { components } from 'react-select'
import SweetAlert from 'react-bootstrap-sweetalert'
import SelectWithCryptoImages from '../../../../components/UI/SelectWithCryptoImages'
import { Trans, useTranslation } from 'react-i18next'
import CheckGpuErrorMessage from '../../../Workers/components/modals/CheckGpuErrorMessage'
import Switch from 'react-switch'
import GPUBLockMobile from '../../../Workers/components/modals/GPUBLockMobile'
import GPUBLockMobileSelect from '../../../Workers/components/modals/GPUBLockMobileSelect'
import SaveProfileDialog from '../../../Workers/components/modals/SaveProfileDialog'

const OverclockingProfileTab = props => {
  const [advancedPage, setAdvancedPage] = useState(false)
  const [profileDataGot, setProfileDataGot] = useState(false)
  const { t } = useTranslation('common')
  const [coreClock, setCoreClock] = useState('')
  const [memoryClock, setMemoryClock] = useState('')
  const [powerLimit, setPowerLimit] = useState('')
  const [fan, setFan] = useState('')
  const [coreClockAMD, setCoreClockAMD] = useState('')
  const [memoryClockAMD, setMemoryClockAMD] = useState('')

  const [coreVddcAMD, setCoreVddcAMD] = useState('')
  const [memoryVddcAMD, setMemoryVddcAMD] = useState('')
  const [fanAMD, setFanAMD] = useState('')
  const [action, setAction] = useState('on_fly')
  const [dpm, setDPM] = useState('1')
  const [mdpm, setMDPM] = useState('1')
  const [socFrequency, setSocFrequency] = useState('')
  const [memControlVoltage, setmemControlVoltage] = useState('')
  const [socVDDmax, setsocVDDmax] = useState('')
  const [haveAMD1000, setHaveAMD1000] = useState(false)
  const [differentCards, setDifferentCards] = useState(false)
  const [differentManufacturer, setDifferentManufacturer] = useState(false)
  const [manufacturer, setManufacturer] = useState(false)

  const [profile, setProfile] = useState(null)

  useEffect(() => {
    setAdvancedPage(profile && profile.type === 'advanced')
    if (profile && profile.type === 'general' && (profile.gpuClock !== null || profile.powerLimit !== null)) {
      setCoreClock(profile.gpuClock || '')
      setCoreClockAMD(profile.gpuClock || '')
      setMemoryClock(profile.memClock || '')
      setMemoryClockAMD(profile.memClock || '')
      setPowerLimit(profile.powerLimit || '')
      setMemoryVddcAMD(profile.memVddc || '')
      setCoreVddcAMD(profile.coreVddc || '')
      setFan(profile.fan || '')
      setFanAMD(profile.fan || '')
      setSocFrequency(profile.socFrequency || '')
      setmemControlVoltage(profile.memControlVoltage || '')
      setsocVDDmax(profile.socVDDmax || '')

      setDPM(profile.dpm)
      setMDPM(profile.mdpm)
    }
    if (profile && profile.action) {
      setAction(profile.action)
    }
    setAlgorithm(
      profile && profile.overclockingAlgo
        ? {
            label: profile.overclockingAlgo,
            value: profile.overclockingAlgo,
            coins:
              props.algoCoinsList &&
              props.algoCoinsList.find(algo => algo.algo === profile.overclockingAlgo) &&
              props.algoCoinsList.find(algo => algo.algo === profile.overclockingAlgo).coins,
          }
        : null,
    )
    if (profile && profile.amdmemorytweak) {
      setAmdmemorytweak(true)
      setAmdmemorytweakString2(profile.amdmemorytweak)
      setAmdmemorytweakString(profile.amdmemorytweak)
    } else {
      setAmdmemorytweak(false)
    }
    if (profile && profile.onGodAnEthArgPill) {
      setOhGodAnETHlagementPill(true)
      setOhGodAnETHlagementPillString(profile.onGodAnEthArgPill)
      setOhGodAnETHlagementPillString2(profile.onGodAnEthArgPill)
    } else {
      setOhGodAnETHlagementPill(false)
    }
    if (profile && profile.autoTune) {
      setAutoTune(true)
      setAutoTuneEditString(profile.autoTune)
      setAutoTuneEditString2(profile.autoTune)
    } else {
      setAutoTune(false)
    }
    setP0(profile && profile.p0)

    const nvidia = []
    const amd = []
    const videoCardsNames = []
    if (profile && profile.overclockingCards && profile.overclockingCards.length > 0) {
      profile.overclockingCards.forEach(card => {
        if (card.cardType === 'nvidia') {
          nvidia.push(card.id)
        } else if (card.cardType === 'amd') {
          amd.push(card.id)
        }
        if (
          videoCardsNames.length === 0 ||
          (videoCardsNames.length > 0 && videoCardsNames[0] !== card.gpuName.toLowerCase())
        )
          videoCardsNames.push(card.gpuName.toLowerCase())
        if (
          card.cardType.toLowerCase() === 'amd' &&
          (card.gpuName.includes('5300') ||
            card.gpuName.includes('5500') ||
            card.gpuName.includes('5600') ||
            card.gpuName.includes('5700') ||
            card.gpuName.includes('6300') ||
            card.gpuName.includes('6400') ||
            card.gpuName.includes('6500') ||
            card.gpuName.includes('6600') ||
            card.gpuName.includes('6650') ||
            card.gpuName.includes('6700') ||
            card.gpuName.includes('6750') ||
            card.gpuName.includes('6800') ||
            card.gpuName.includes('6900') ||
            card.gpuName.includes('6950'))
        ) {
          setHaveAMD1000(true)
        }
      })
      //console.log(videoCardsNames)
      if (videoCardsNames.length > 1) setDifferentCards(true)

      if (nvidia.length > 0 && amd.length > 0) setDifferentManufacturer(true)
    }
    setProfileDataGot(true)
  }, [profile])

  const setApplyAction = e => {
    setAction(e.target.value)
  }

  const [cardsForEach, setCardsForEach] = useState([])

  const checkCardsFunc = (val, card, param) => {
    const checkCard = cardsForEach.find(c => +c.id === +card.id)
    if (!checkCard) {
      setCardsForEach([...cardsForEach, card])
    } else {
      const updatedCards = cardsForEach.map(c => {
        if (+c.id === +card.id) c[param] = val
        return c
      })
      setCardsForEach(updatedCards)
    }
  }

  const setCoreClockSpecial = (value, card) => {
    checkCardsFunc(value, card, 'gpuClock')
    card.gpuClock = value
  }
  const setMemoryClockSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memClock')
    card.memClock = value
  }
  const setPowerLimitSpecial = (value, card) => {
    checkCardsFunc(value, card, 'powerLimit')
    card.powerLimit = value
  }
  const setFanSpecial = (value, card) => {
    checkCardsFunc(value, card, 'fan')
    card.fan = value
  }
  const setCoreVddcSpecial = (value, card) => {
    checkCardsFunc(value, card, 'coreVddc')
    card.coreVddc = value
  }
  const setMemVddcSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memVddc')
    card.memVddc = value
  }
  const setDPMVddcSpecial = (event, card) => {
    checkCardsFunc(event.target.value, card, 'dpm')
    card.dpm = event.target.value
  }
  const setMDPMVddcSpecial = (event, card) => {
    checkCardsFunc(event.target.value, card, 'mdpm')
    card.mdpm = event.target.value
  }

  const setSocFrequencySpecial = (value, card) => {
    checkCardsFunc(value, card, 'socFrequency')
    card.socFrequency = value
  }

  const setmemControlVoltageSpecial = (value, card) => {
    checkCardsFunc(value, card, 'memControlVoltage')
    card.memControlVoltage = value
  }
  const setsocVDDmaxSpecial = (value, card) => {
    checkCardsFunc(value, card, 'socVDDmax')
    card.socVDDmax = value
  }
  const [showCardsErrorAlertProfileSave, setShowCardsErrorAlertProfileSave] = useState(false)
  const [showCardsErrorAlertProfileUpdate, setShowCardsErrorAlertProfileUpdate] = useState(false)
  const [showCardsErrorAlertProfileUpdateApply, setShowCardsErrorAlertProfileUpdateApply] = useState(false)
  const [gpuProfileSaveModal, setGpuProfileSaveModal] = useState(false)
  const [gpuProfileUpdateModal, setGpuProfileUpdateModal] = useState(false)
  const [gpuProfileUpdateApplyModal, setGpuProfileUpdateApplyModal] = useState(false)
  const [cardErrors, setErrorStrings] = useState([])

  const toggleGpuErrMessProfileSave = () => {
    setGpuProfileSaveModal(!gpuProfileSaveModal)
  }
  const toggleGpuErrMessProfileUpdate = () => {
    setGpuProfileUpdateModal(!gpuProfileUpdateModal)
  }
  const toggleGpuErrMessProfileUpdateApply = () => {
    setGpuProfileUpdateApplyModal(!gpuProfileUpdateApplyModal)
  }
  const toggleSaveDialog = () => {
    setShowSaveDefault(!showSaveDefault)
  }
  const toggleUpdateDialog = () => {
    setShowUpdate(!showUpdate)
  }

  const answerYesSetForGpuCheckProfileUpdate = async () => {
    await UpdateProfileGeneral(profileName, profileType, slot)
    setGpuProfileUpdateModal(!gpuProfileUpdateModal)
  }

  const answerYesSetForGpuCheckProfileUpdateApply = async () => {
    await UpdateProfileGeneral(profileName, profileType, slot)
    await props.applyOverclockingProfile({
      rigId: parseInt(profile.rigId),
      id: parseInt(profile.id),
    })
    props.updateRig()
    setGpuProfileUpdateApplyModal(!gpuProfileUpdateApplyModal)
  }

  const answerYesSetForGpuCheckProfileSave = async () => {
    await SaveProfileGeneral(profileName, profileType, slot)
    setGpuProfileSaveModal(!gpuProfileSaveModal)
  }

  const [algorithm, setAlgorithm] = useState('')
  const locked = false
  const [amdmemorytweak, setAmdmemorytweak] = useState(false)
  const [amdmemorytweakEdit, setAmdmemorytweakEdit] = useState(false)
  const [amdmemorytweakString, setAmdmemorytweakString] = useState('amdmemorytweak --REF 30')
  const [amdmemorytweakString2, setAmdmemorytweakString2] = useState('amdmemorytweak --REF 30')
  const [ohGodAnETHlagementPill, setOhGodAnETHlagementPill] = useState(false)
  const [ohGodAnETHlagementPillEdit, setOhGodAnETHlagementPillEdit] = useState(false)
  const [ohGodAnETHlagementPillString, setOhGodAnETHlagementPillString] = useState('OhGodAnETHlagement')
  const [ohGodAnETHlagementPillString2, setOhGodAnETHlagementPillString2] = useState('OhGodAnETHlagement')

  const setAlgo = option => {
    setAlgorithm(option)
  }

  const resetAmdmemorytweak = () => {
    setAmdmemorytweakString2(amdmemorytweakString)
    setAmdmemorytweakEdit(false)
  }
  const saveAmdmemorytweak = () => {
    setAmdmemorytweakString(amdmemorytweakString2)
    setAmdmemorytweakEdit(false)
  }
  const resetOhGodAnETHlagementPill = () => {
    setOhGodAnETHlagementPillString2(ohGodAnETHlagementPillString)
    setOhGodAnETHlagementPillEdit(false)
  }
  const saveOhGodAnETHlagementPill = () => {
    setOhGodAnETHlagementPillString(ohGodAnETHlagementPillString2)
    setOhGodAnETHlagementPillEdit(false)
  }

  const [autoTune, setAutoTune] = useState(false)
  const [autoTuneEdit, setAutoTuneEdit] = useState(false)
  const [autoTuneEditString, setAutoTuneEditString] = useState('')
  const [autoTuneEditString2, setAutoTuneEditString2] = useState('')
  const [p0, setP0] = useState(false)

  const resetAutoTune = () => {
    setAutoTuneEditString2(autoTuneEditString)
    setAutoTuneEdit(false)
  }
  const saveAutoTune = () => {
    setAutoTuneEditString(autoTuneEditString2)
    setAutoTuneEdit(false)
  }

  const [profileName, setProfileName] = useState('')
  const [profileType, setProfileType] = useState(null)
  const [slot, setSlot] = useState(null)

  const SaveProfile = async (profileName = '', profileType, slot = null) => {
    setProfileName(profileName)
    setProfileType(profileType)
    setSlot(slot)
    if (advancedPage === true || locked) {
      await SaveProfileAdvanced(profileName, profileType, slot)
      setShowSaveDefault(false)
    } else {
      const needsCards = []
      profile.overclockingCards.forEach(card => {
        needsCards.push(card)
      })
      const checkGpu = needsCards.every(
        card => card.gpuName === needsCards[0].gpuName && card.memInfo === needsCards[0].memInfo,
      )

      if (!checkGpu) {
        setGpuProfileSaveModal(true)
      } else {
        await SaveProfileGeneral(profileName, profileType, slot)
        setShowSaveDefault(false)
      }
    }
  }

  const SaveProfileAdvanced = async (profileName, profileType, slot) => {
    const cards = []

    profile.overclockingCards.forEach(card => {
      if (card.cardType === 'amd') {
        cards.push({
          cardId: card.cardId,
          memClock: card.memClock.toString(),
          gpuClock: card.gpuClock.toString(),
          fan: card.fan.toString(),
          coreVddc: card.coreVddc.toString(),
          memVddc: card.memVddc.toString(),
          dpm: card.dpm.toString(),
          mdpm: card.mdpm.toString(),
          socFrequency: card.socFrequency.toString(),
          memControlVoltage: card.memControlVoltage.toString(),
          socVDDmax: card.socVDDmax.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      } else {
        cards.push({
          cardId: card.cardId,
          powerLimit: card.powerLimit.toString(),
          gpuClock: card.gpuClock.toString(),
          memClock: card.memClock.toString(),
          fan: card.fan.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      }
    })

    const result = await props.saveOverclockingProfile({
      rigId: profile.rigId,
      name: profileName,
      profileType: profileType,
      slotId: slot,
      type: 'advanced',
      action,
      overclockingAlgo: algorithm ? algorithm.value : null,
      onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
      amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
      autoTune: autoTune === true ? autoTuneEditString : '',
      overclockingCards: cards,
    })
    if (result) {
      setProfile({
        ...profile,
        id: result.id,
        name: profileName,
        profileType: profileType,
        slotId: slot,
      })
      setShowSaveDefault(false)
    }
    props.setOverclockingProfilesList({ page: 1, size: 1000 })
  }

  const SaveProfileGeneral = async (profileName, profileType, slot) => {
    if (profile.cardType === 'amd') {
      const result = await props.saveOverclockingProfile({
        rigId: profile.rigId,
        name: profileName,
        type: 'general',
        cardType: 'amd',
        profileType: profileType,
        slotId: slot,
        gpuName: profile.gpuName,
        memClock: memoryClockAMD.toString(),
        gpuClock: coreClockAMD.toString(),
        fan: fanAMD.toString(),
        coreVddc: coreVddcAMD.toString(),
        memVddc: memoryVddcAMD.toString(),
        dpm: dpm.toString(),
        mdpm: mdpm.toString(),
        socFrequency: socFrequency ? socFrequency.toString() : null,
        memControlVoltage: memControlVoltage ? memControlVoltage.toString() : null,
        socVDDmax: socVDDmax ? socVDDmax.toString() : null,
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
      if (result) {
        setProfile({
          ...profile,
          id: result.id,
          name: profileName,
          profileType: profileType,
          slotId: slot,
        })
        setShowSaveDefault(false)
      }
    }
    if (profile.cardType === 'nvidia') {
      const result = await props.saveOverclockingProfile({
        rigId: profile.rigId,
        name: profileName,
        type: 'general',
        cardType: 'nvidia',
        profileType: profileType,
        slotId: slot,
        gpuName: profile.gpuName,
        powerLimit: powerLimit.toString(),
        gpuClock: coreClock.toString(),
        memClock: memoryClock.toString(),
        fan: fan.toString(),
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
      if (result) {
        setProfile({
          ...profile,
          id: result.id,
          name: profileName,
          profileType: profileType,
          slotId: slot,
        })
        setShowSaveDefault(false)
      }
    }
    props.setOverclockingProfilesList({ page: 1, size: 1000 })
  }

  const UpdateProfile = async (profileName, profileType, slot, apply = false) => {
    if (advancedPage === true || locked) {
      await UpdateProfileAdvanced(profileName, profileType, slot)
      if (apply) {
        await props.applyOverclockingProfile({
          rigId: parseInt(profile.rigId),
          id: parseInt(profile.id),
        })
        props.updateRig()
      }
    } else {
      const needsCards = []
      profile.overclockingCards.forEach(card => {
        needsCards.push(card)
      })
      const checkGpu = needsCards.every(
        card => card.gpuName === needsCards[0].gpuName && card.memInfo === needsCards[0].memInfo,
      )

      if (!checkGpu) {
        if (apply) {
          setGpuProfileUpdateApplyModal(true)
        } else {
          setGpuProfileUpdateModal(true)
        }
      } else {
        await UpdateProfileGeneral(profileName, profileType, slot)
        if (apply) {
          await props.applyOverclockingProfile({
            rigId: parseInt(profile.rigId),
            id: parseInt(profile.id),
          })
          props.updateRig()
        }
      }
    }
  }

  const UpdateProfileGeneral = async (profileName, profileType, slot) => {
    if (profile.cardType === 'amd') {
      const res = await props.updateOverclockingProfile({
        id: profile.id,
        rigId: profile.rigId,
        name: profileName,
        profileType: profileType,
        slotId: slot,
        type: 'general',
        cardType: 'amd',
        gpuName: profile.gpuName,
        memClock: memoryClockAMD.toString(),
        gpuClock: coreClockAMD.toString(),
        fan: fanAMD.toString(),
        coreVddc: coreVddcAMD.toString(),
        memVddc: memoryVddcAMD.toString(),
        dpm: dpm.toString(),
        mdpm: mdpm.toString(),
        socFrequency: socFrequency ? socFrequency.toString() : null,
        memControlVoltage: memControlVoltage ? memControlVoltage.toString() : null,
        socVDDmax: socVDDmax ? socVDDmax.toString() : null,
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
      if (res) {
        setProfile({
          ...profile,
          name: profileName,
          profileType: profileType,
          slotId: slot,
        })
        setShowUpdate(false)
      }
    }
    if (profile.cardType === 'nvidia') {
      const res = await props.updateOverclockingProfile({
        id: profile.id,
        rigId: profile.rigId,
        name: profileName,
        profileType: profileType,
        slotId: slot,
        type: 'general',
        cardType: 'nvidia',
        gpuName: profile.gpuName,
        powerLimit: powerLimit.toString(),
        gpuClock: coreClock.toString(),
        memClock: memoryClock.toString(),
        fan: fan.toString(),
        action,
        overclockingAlgo: algorithm ? algorithm.value : null,
        onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
        amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
        autoTune: autoTune === true ? autoTuneEditString : '',
        overclockingCards: [],
        p0: p0 === true ? p0 : false,
      })
      if (res) {
        setProfile({
          ...profile,
          name: profileName,
          profileType: profileType,
          slotId: slot,
        })
        setShowUpdate(false)
      }
    }
    props.setOverclockingProfilesList({ page: 1, size: 1000 })
  }
  const UpdateProfileAdvanced = async (profileName, profileType, slot) => {
    const cards = []

    profile.overclockingCards.forEach(card => {
      if (card.cardType === 'amd') {
        cards.push({
          cardId: card.cardId,
          memClock: card.memClock.toString(),
          gpuClock: card.gpuClock.toString(),
          fan: card.fan.toString(),
          coreVddc: card.coreVddc.toString(),
          memVddc: card.memVddc.toString(),
          dpm: card.dpm.toString(),
          mdpm: card.mdpm.toString(),
          socFrequency: card.socFrequency.toString(),
          memControlVoltage: card.memControlVoltage.toString(),
          socVDDmax: card.socVDDmax.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      } else {
        cards.push({
          cardId: card.cardId,
          powerLimit: card.powerLimit.toString(),
          gpuClock: card.gpuClock.toString(),
          memClock: card.memClock.toString(),
          fan: card.fan.toString(),
          cardType: card.cardType,
          cardSlot: card.cardSlot,
          gpuName: card.gpuName,
          cardNum: card.cardNum,
          vendorName: card.vendorName.toString(),
          memInfo: card.memInfo.toString(),
          memType: card.memType.toString(),
          fwInfo: card.fwInfo.toString(),
          vram: card.vram.toString(),
        })
      }
    })

    const res = await props.updateOverclockingProfile({
      id: profile.id,
      rigId: profile.rigId,
      name: profileName,
      profileType: profileType,
      slotId: slot,
      type: 'advanced',
      action,
      overclockingAlgo: algorithm ? algorithm.value : null,
      onGodAnEthArgPill: ohGodAnETHlagementPill === true ? ohGodAnETHlagementPillString : '',
      amdmemorytweak: amdmemorytweak === true ? amdmemorytweakString : '',
      autoTune: autoTune === true ? autoTuneEditString : '',
      overclockingCards: cards,
      p0: p0 === true ? p0 : false,
    })
    if (res) {
      setProfile({
        ...profile,
        name: profileName,
        profileType: profileType,
        slotId: slot,
      })
      setShowUpdate(false)
    }
    props.setOverclockingProfilesList({ page: 1, size: 1000 })
  }

  const saveAndApply = async () => {
    setIsSaveAndApply(true)
  }

  const [showSaveDefault, setShowSaveDefault] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [isSaveAndApply, setIsSaveAndApply] = useState(false)

  const checkWhatClicked = e => {
    setLastClickedElement(e.target)
  }

  const [lastClickedElement, setLastClickedElement] = useState(null)
  const [lastClickedElementClickCount, setLastClickedElementClickCount] = useState(0)

  const doubleCardUp = (e, card, number, property) => {
    number--
    let clickCount = 0
    if (e.target === lastClickedElement) clickCount = lastClickedElementClickCount + +1
    const sum = number - clickCount
    if (sum > -1) {
      const videoCard = profile.overclockingCards.filter(videoCard => videoCard.cardType === card.cardType)[sum]
      if (videoCard) {
        if (property === 'gpuClock') videoCard.gpuClock = card.gpuClock
        if (property === 'memClock') videoCard.memClock = card.memClock
        if (property === 'coreVddc') videoCard.coreVddc = card.coreVddc
        if (property === 'fan') videoCard.fan = card.fan
        if (property === 'dpm') videoCard.dpm = card.dpm
        if (property === 'mdpm') videoCard.mdpm = card.mdpm
        if (property === 'powerLimit') videoCard.powerLimit = card.powerLimit
        if (property === 'memVddc') videoCard.memVddc = card.memVddc
      }
    }
    setLastClickedElementClickCount(clickCount)
  }

  const doubleCardDown = (e, card, number, property) => {
    number++
    let clickCount = 0
    if (e.target === lastClickedElement) clickCount = lastClickedElementClickCount + +1
    const sum = number + +clickCount
    if (profile.overclockingCards && sum <= profile.overclockingCards.length) {
      const videoCard = profile.overclockingCards.filter(videoCard => videoCard.cardType === card.cardType)[sum]
      if (videoCard) {
        if (property === 'gpuClock') videoCard.gpuClock = card.gpuClock
        if (property === 'memClock') videoCard.memClock = card.memClock
        if (property === 'coreVddc') videoCard.coreVddc = card.coreVddc
        if (property === 'fan') videoCard.fan = card.fan
        if (property === 'dpm') videoCard.dpm = card.dpm
        if (property === 'mdpm') videoCard.mdpm = card.mdpm
        if (property === 'powerLimit') videoCard.powerLimit = card.powerLimit
        if (property === 'memVddc') videoCard.memVddc = card.memVddc
      }
    }
    setLastClickedElementClickCount(clickCount)
  }

  const checkOverclockingErrors = (next, showAlert) => {
    if (advancedPage === true || locked) {
      const errorStrings = []
      setErrorStrings([])
      profile.overclockingCards.map(card => {
        let errorString = ''
        if (!card.gpuClock || card.gpuClock.length <= 0)
          errorString.length > 0
            ? (errorString += ', ' + t('pages.workers.coreClockError'))
            : (errorString = t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.coreClockError'))
        if (!card.memClock || card.memClock.length <= 0)
          errorString.length > 0
            ? (errorString += ', ' + t('pages.workers.memoryClockError'))
            : (errorString = t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.memoryClockError'))
        if (card.cardType === 'amd' && (!card.coreVddc || card.coreVddc.length <= 0))
          errorString.length > 0
            ? (errorString += ', ' + t('pages.workers.coreVDDCError'))
            : (errorString = t('pages.workers.GPU') + card.cardNum + ' - ' + t('pages.workers.coreVDDCError'))
        if (errorString.length > 0) errorStrings.push(errorString + ' ' + t('pages.workers.notSpecified'))
        return card
      })
      setErrorStrings(errorStrings)
      if (errorStrings.length > 0) {
        showAlert(true)
        return false
      }
      next()
    } else {
      if (
        (isNaN(parseFloat(coreVddcAMD)) || isNaN(parseFloat(memoryClockAMD)) || isNaN(parseFloat(coreClockAMD))) &&
        (isNaN(parseFloat(memoryClock)) || isNaN(parseFloat(coreClock)))
      ) {
        setErrorStrings([t('pages.workers.somethingNotSpecified')])
        showAlert(true)
        return false
      } else {
        next()
      }
    }
  }

  const [search, setSearch] = useState('')
  const [selectProfile, setSelectProfile] = useState(true)
  const profilesList = useRef(null)
  const toggleprofilesList = useRef(null)
  function useOutsideAlerter(ref, buttonRef) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          selectProfile &&
          ref &&
          ref.current &&
          !ref.current.contains(event.target) &&
          buttonRef &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setSelectProfile(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, selectProfile, buttonRef])
  }
  useOutsideAlerter(profilesList, toggleprofilesList)

  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const deleteProfileFunc = async () => {
    setShowAlertDelete(false)
    await props.deleteProfile({ id: profile.id })
    setProfile(null)
    setProfileDataGot(false)
  }

  const [showSaveActions, setShowSaveActions] = useState(false)

  return (
    <div onClick={e => e.stopPropagation()}>
      <SweetAlert
        title={t('sweetAlerts.areConfirm')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showAlertDelete}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          deleteProfileFunc()
        }}
        onCancel={() => {
          setShowAlertDelete(false)
        }}
        confirmBtnText={t('sweetAlerts.confirm')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={true}
      >
        {t('pages.overclocking.profileRemove')}
      </SweetAlert>
      <SweetAlert
        title={t('pages.workers.notAllParametersSpecified')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showCardsErrorAlertProfileUpdate}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          setShowCardsErrorAlertProfileUpdate(false)
          UpdateProfile()
        }}
        onCancel={() => {
          setShowCardsErrorAlertProfileUpdate(false)
        }}
        confirmBtnText={t('sweetAlerts.yes')}
        calcelBtnText={t('sweetAlerts.no')}
        showCloseButton={false}
      >
        <div className={'videoCards_errors'}>
          {cardErrors.map(error => {
            return <div>{error}</div>
          })}
        </div>
        <h5>{t('pages.workers.wouldYouLikeContinueAnyway')}</h5>
      </SweetAlert>
      <SweetAlert
        title={t('pages.workers.notAllParametersSpecified')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showCardsErrorAlertProfileSave}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          toggleSaveDialog()
          setShowCardsErrorAlertProfileSave(false)
        }}
        onCancel={() => {
          setShowCardsErrorAlertProfileSave(false)
        }}
        confirmBtnText={t('sweetAlerts.yes')}
        calcelBtnText={t('sweetAlerts.no')}
        showCloseButton={false}
      >
        <div className={'videoCards_errors'}>
          {cardErrors.map(error => {
            return <div>{error}</div>
          })}
        </div>
        <h5>{t('pages.workers.wouldYouLikeContinueAnyway')}</h5>
      </SweetAlert>
      <SweetAlert
        title={t('pages.workers.notAllParametersSpecified')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={showCardsErrorAlertProfileUpdateApply}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          saveAndApply()
          setShowCardsErrorAlertProfileUpdateApply(false)
        }}
        onCancel={() => {
          setShowCardsErrorAlertProfileUpdateApply(false)
        }}
        confirmBtnText={t('sweetAlerts.yes')}
        calcelBtnText={t('sweetAlerts.no')}
        showCloseButton={false}
      >
        <div className={'videoCards_errors'}>
          {cardErrors.map(error => {
            return <div>{error}</div>
          })}
        </div>
        <h5>{t('pages.workers.wouldYouLikeContinueAnyway')}</h5>
      </SweetAlert>

      <SweetAlert
        title={t('pages.workers.LoadApplyProfile')}
        warning
        showCancel
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='secondary'
        show={isSaveAndApply}
        focusCancelBtn={true}
        focusConfirmBtn={false}
        confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
        cancelBtnStyle={{
          borderColor: 'rgb(40, 96, 144)',
          boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
        }}
        onConfirm={() => {
          UpdateProfile(profile.name, profile.profileType, profile.slotId, true)
          setIsSaveAndApply(false)
        }}
        onCancel={() => {
          setIsSaveAndApply(false)
        }}
        confirmBtnText={t('sweetAlerts.yes')}
        cancelBtnText={t('sweetAlerts.cancel')}
        showCloseButton={false}
      ></SweetAlert>

      <SaveProfileDialog
        open={showSaveDefault}
        toggle={toggleSaveDialog}
        save={SaveProfile}
        advancedPage={advancedPage}
      />
      <SaveProfileDialog
        name={profile ? profile.name : ''}
        profileType={profile ? profile.profileType : ''}
        open={showUpdate}
        toggle={toggleUpdateDialog}
        save={UpdateProfile}
        advancedPage={advancedPage}
        slot={profile && profile.slotId}
        apply={isSaveAndApply}
        isUpdate={showUpdate}
      />
      <div>
        <ModalHeader tag={'h4'} toggle={props.toggle}>
          {t('pages.overclocking.overclockingProfile')} {profile ? profile.name : ''}
        </ModalHeader>
        <ModalBody>
          <CheckGpuErrorMessage
            gpuModal={gpuProfileSaveModal}
            toggleGpuErrMess={toggleGpuErrMessProfileSave}
            answerYesSet={answerYesSetForGpuCheckProfileSave}
          />
          <CheckGpuErrorMessage
            gpuModal={gpuProfileUpdateModal}
            toggleGpuErrMess={toggleGpuErrMessProfileUpdate}
            answerYesSet={answerYesSetForGpuCheckProfileUpdate}
          />
          <CheckGpuErrorMessage
            gpuModal={gpuProfileUpdateApplyModal}
            toggleGpuErrMess={toggleGpuErrMessProfileUpdateApply}
            answerYesSet={answerYesSetForGpuCheckProfileUpdateApply}
          />
          <Button
            innerRef={toggleprofilesList}
            color={'primary'}
            className={'select-profile'}
            onClick={() => setSelectProfile(!selectProfile)}
          >
            {selectProfile || !profile ? t('pages.workers.hideProfiles') : t('pages.workers.showProfiles')}
          </Button>
          <div
            ref={profilesList}
            style={{ position: profile ? 'absolute' : 'static' }}
            className={selectProfile || !profile ? 'profiles-list show' : 'profiles-list'}
          >
            <div className={'quicksearch'}>
              <Input
                onChange={e => {
                  setSearch(e.target.value)
                }}
                value={search}
                name={'quicksearch'}
                className={'form-control'}
                placeholder={t('pages.workers.quickSearch')}
              />
              <i
                style={{ display: search ? 'block' : 'none' }}
                className='fas fa-times'
                onClick={() => setSearch('')}
              ></i>
            </div>
            <div className={'profiles_list__profiles'}>
              {props.rig &&
                props.overclockingProfilesList
                  ?.filter(
                    profileF =>
                      profileF.rigId === props.rig.id &&
                      profileF.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 &&
                      (!profile || (profile && profile.id !== profileF.id)),
                  )
                  .map(profile => {
                    return (
                      <div
                        key={profile.id}
                        className={'overclocking-profile'}
                        onClick={() => {
                          setProfile(profile)
                          setSelectProfile(false)
                          setProfileDataGot(false)
                        }}
                      >
                        {profile.name}
                      </div>
                    )
                  })}
            </div>
          </div>
          {profile && (
            <>
              <Tabs>
                <TabList className={'card_tabs'}>
                  {profile && profile.type === 'general' ? (
                    <Tab>{t('pages.workers.general')}</Tab>
                  ) : (
                    <Tab>{t('pages.workers.advanced')}</Tab>
                  )}
                  <Tab>{t('pages.workers.additional')}</Tab>
                </TabList>

                {profileDataGot && profile && profile.type === 'general' ? (
                  <TabPanel>
                    <Form className={'overclocking-default'}>
                      <div className={'description'}>{t('pages.workers.generalTip')}</div>

                      {profile && profile.cardType !== 'amd' ? (
                        <div className={'general_nvidia'}>
                          <h6>NVIDIA</h6>
                          <Row>
                            <Col>
                              <div className={'form-group advanced_card_field'}>
                                <Label>
                                  {coreClock >= 500
                                    ? t('pages.workers.lockCoreClock')
                                    : t('pages.workers.coreClockOffset')}
                                  , {t('pages.workers.Mhz')}
                                </Label>
                                <UiRangeSlider
                                  default={coreClock}
                                  setValue={setCoreClock}
                                  min={-9999}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.memoryClock')}</Label>
                                <UiRangeSlider
                                  default={memoryClock}
                                  setValue={setMemoryClock}
                                  min={-9999}
                                  max={9999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.powerLimit')}</Label>
                                <UiRangeSlider
                                  default={powerLimit}
                                  setValue={setPowerLimit}
                                  min={0}
                                  max={999}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                              <div className={'form-group advanced_card_field'}>
                                <Label>{t('pages.workers.FAN')}</Label>
                                <UiRangeSlider
                                  default={fan}
                                  isFan={true}
                                  setValue={setFan}
                                  min={0}
                                  max={100}
                                  step={1}
                                  disabled={locked}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                      {profile && profile.cardType === 'amd' ? (
                        <div className={'general_amd'}>
                          <h6>AMD</h6>
                          <div>
                            {haveAMD1000 ? (
                              <div>
                                <Row className={'advanced_card_row'}>
                                  <Col>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label>{t('pages.workers.coreClock')}</Label>
                                      <UiRangeSlider
                                        default={coreClockAMD}
                                        setValue={setCoreClockAMD}
                                        min={-9999}
                                        max={9999}
                                        step={1}
                                        disabled={locked}
                                      />
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label>{t('pages.workers.memoryClock')}</Label>
                                      <UiRangeSlider
                                        default={memoryClockAMD}
                                        setValue={setMemoryClockAMD}
                                        min={-9999}
                                        max={9999}
                                        step={1}
                                        disabled={locked}
                                      />
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label>{t('pages.workers.coreVDDC')}</Label>
                                      <UiRangeSlider
                                        default={coreVddcAMD}
                                        setValue={setCoreVddcAMD}
                                        min={0}
                                        max={9999}
                                        step={1}
                                        disabled={locked}
                                      />
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label>{t('pages.workers.memoryVDDC')}</Label>
                                      <UiRangeSlider
                                        default={memoryVddcAMD}
                                        setValue={setMemoryVddcAMD}
                                        min={0}
                                        max={9999}
                                        step={1}
                                        disabled={locked}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className={'advanced_card_row'}>
                                  <Col>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label>{t('pages.workers.socFrequency')}</Label>
                                      <UiRangeSlider
                                        default={socFrequency}
                                        setValue={setSocFrequency}
                                        min={0}
                                        max={9999}
                                        step={1}
                                        disabled={locked}
                                      />
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label>{t('pages.workers.soCVDDMax')}</Label>
                                      <UiRangeSlider
                                        default={socVDDmax}
                                        setValue={setsocVDDmax}
                                        min={0}
                                        max={9999}
                                        step={1}
                                        disabled={locked}
                                      />
                                    </div>
                                    <div className={'form-group advanced_card_field'}>
                                      <Label>{t('pages.workers.memoryController')}</Label>
                                      <UiRangeSlider
                                        default={memControlVoltage}
                                        setValue={setmemControlVoltage}
                                        min={0}
                                        max={9999}
                                        step={1}
                                        disabled={locked}
                                      />
                                    </div>
                                    <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                      <Label>{t('pages.workers.FAN')}</Label>
                                      <UiRangeSlider
                                        default={fanAMD}
                                        setValue={setFanAMD}
                                        isFan={true}
                                        min={0}
                                        max={100}
                                        step={1}
                                        disabled={locked}
                                      />
                                    </div>
                                    <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                      <Label>{t('pages.workers.DPM')}</Label>
                                      <select
                                        className={'form-control'}
                                        onChange={event => setDPM(event.target.value)}
                                        defaultValue={dpm}
                                        disabled={locked}
                                      >
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                      </select>
                                    </div>
                                    <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                      <Label>{t('pages.workers.MDPM')}</Label>
                                      <select
                                        className={'form-control'}
                                        onChange={event => setMDPM(event.target.value)}
                                        defaultValue={mdpm}
                                        disabled={locked}
                                      >
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              <Row className={'advanced_card_row'}>
                                <Col>
                                  <div className={'form-group advanced_card_field'}>
                                    <Label>{t('pages.workers.coreClock')}</Label>
                                    <UiRangeSlider
                                      default={coreClockAMD}
                                      setValue={setCoreClockAMD}
                                      min={-9999}
                                      max={9999}
                                      step={1}
                                      disabled={locked}
                                    />
                                  </div>
                                  <div className={'form-group advanced_card_field'}>
                                    <Label>{t('pages.workers.memoryClock')}</Label>
                                    <UiRangeSlider
                                      default={memoryClockAMD}
                                      setValue={setMemoryClockAMD}
                                      min={-9999}
                                      max={9999}
                                      step={1}
                                      disabled={locked}
                                    />
                                  </div>
                                  <div className={'form-group advanced_card_field'}>
                                    <Label>{t('pages.workers.coreVDDC')}</Label>
                                    <UiRangeSlider
                                      default={coreVddcAMD}
                                      setValue={setCoreVddcAMD}
                                      min={0}
                                      max={9999}
                                      step={1}
                                      disabled={locked}
                                    />
                                  </div>
                                  <div className={'form-group advanced_card_field'}>
                                    <Label>{t('pages.workers.memoryVDDC')}</Label>
                                    <UiRangeSlider
                                      default={memoryVddcAMD}
                                      setValue={setMemoryVddcAMD}
                                      min={0}
                                      max={9999}
                                      step={1}
                                      disabled={locked}
                                    />
                                  </div>
                                  <div className={'form-group advanced_card_field'}>
                                    <Label>{t('pages.workers.memoryController')}</Label>
                                    <UiRangeSlider
                                      default={memControlVoltage}
                                      setValue={setmemControlVoltage}
                                      min={0}
                                      max={9999}
                                      step={1}
                                      disabled={locked}
                                    />
                                  </div>
                                  <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                    <Label>{t('pages.workers.FAN')}</Label>
                                    <UiRangeSlider
                                      default={fanAMD}
                                      setValue={setFanAMD}
                                      isFan={true}
                                      min={0}
                                      max={100}
                                      step={1}
                                      disabled={locked}
                                    />
                                  </div>
                                  <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                    <Label>{t('pages.workers.DPM')}</Label>
                                    <select
                                      className={'form-control'}
                                      onChange={event => setDPM(event.target.value)}
                                      defaultValue={dpm}
                                      disabled={locked}
                                    >
                                      <option value={1}>1</option>
                                      <option value={2}>2</option>
                                      <option value={3}>3</option>
                                      <option value={4}>4</option>
                                      <option value={5}>5</option>
                                      <option value={6}>6</option>
                                      <option value={7}>7</option>
                                    </select>
                                  </div>
                                  <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                    <Label>{t('pages.workers.MDPM')}</Label>
                                    <select
                                      className={'form-control'}
                                      onChange={event => setMDPM(event.target.value)}
                                      defaultValue={mdpm}
                                      disabled={locked}
                                    >
                                      <option value={1}>1</option>
                                      <option value={2}>2</option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </Form>
                  </TabPanel>
                ) : (
                  <TabPanel onClick={checkWhatClicked}>
                    <Form className={'d-md-none advanced-mobile overclocking-advanced'}>
                      <div className={'description'}>{t('pages.workers.individualSettingsForEachCard')}</div>
                      {differentManufacturer ? (
                        <div className={'selectManufacturer'}>
                          {t('pages.workers.AMDNVIDIACardsDetected')}
                          <br />
                          <Switch
                            uncheckedIcon={
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                                  fontSize: 10,
                                  color: '#fff',
                                  paddingRight: 10,
                                }}
                              >
                                {' '}
                                NVIDIA
                              </div>
                            }
                            checkedIcon={
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                                  fontSize: 10,
                                  color: '#fff',
                                  paddingLeft: 10,
                                }}
                              >
                                {' '}
                                AMD
                              </div>
                            }
                            onColor='#ff0000'
                            offColor='#008000'
                            onChange={() => {
                              setManufacturer(!manufacturer)
                            }}
                            checked={manufacturer}
                            height={20}
                            width={60}
                          />
                        </div>
                      ) : null}

                      {profile &&
                      profile.overclockingCards &&
                      profile.overclockingCards.filter(card => card.cardType === 'nvidia') &&
                      profile.overclockingCards.filter(card => card.cardType === 'nvidia').length > 0 &&
                      (!differentManufacturer || (differentManufacturer && !manufacturer)) ? (
                        <div>
                          <h5>{t('pages.workers.coreClock')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'nvidia')
                              .map(card => {
                                if (!card.gpuClock) card.gpuClock = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.gpuClock}
                                    key={card.id + 'gpuClock'}
                                    setValueFunc={setCoreClockSpecial}
                                    card={card}
                                    min={-9999}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.memoryClock')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'nvidia')
                              .map(card => {
                                if (!card.memClock) card.memClock = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.memClock}
                                    key={card.id + 'memClock'}
                                    setValueFunc={setMemoryClockSpecial}
                                    card={card}
                                    min={-9999}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.powerLimit')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'nvidia')
                              .map(card => {
                                if (!card.powerLimit) card.powerLimit = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.powerLimit}
                                    key={card.id + 'powerLimit'}
                                    setValueFunc={setPowerLimitSpecial}
                                    card={card}
                                    min={0}
                                    max={999}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.FAN')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'nvidia')
                              .map(card => {
                                if (!card.fan) card.fan = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.fan}
                                    key={card.id + 'fan'}
                                    setValueFunc={setFanSpecial}
                                    card={card}
                                    isFan={true}
                                    min={0}
                                    max={100}
                                  />
                                )
                              })}
                          </div>
                        </div>
                      ) : null}

                      {profile &&
                      profile.overclockingCards &&
                      profile.overclockingCards.filter(card => card.cardType === 'amd') &&
                      profile.overclockingCards.filter(card => card.cardType === 'amd').length > 0 &&
                      (!differentManufacturer || (differentManufacturer && manufacturer)) ? (
                        <div>
                          <h5>{t('pages.workers.coreClock')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'amd')
                              .map(card => {
                                if (!card.gpuClock) card.gpuClock = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.gpuClock}
                                    key={card.id + 'gpuClock'}
                                    setValueFunc={setCoreClockSpecial}
                                    card={card}
                                    min={-9999}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.memoryClock')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'amd')
                              .map(card => {
                                if (!card.memClock) card.memClock = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.memClock}
                                    key={card.id + 'memClock'}
                                    setValueFunc={setMemoryClockSpecial}
                                    card={card}
                                    min={-9999}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.coreVDDC')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'amd')
                              .map(card => {
                                if (!card.coreVddc) card.coreVddc = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.coreVddc}
                                    key={card.id + 'coreVddc'}
                                    setValueFunc={setCoreVddcSpecial}
                                    card={card}
                                    min={0}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.memoryVDDC')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'amd')
                              .map(card => {
                                if (!card.memVddc) card.memVddc = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.memVddc}
                                    key={card.id + 'memVddc'}
                                    setValueFunc={setMemVddcSpecial}
                                    card={card}
                                    min={0}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.memoryController')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'amd')
                              .map(card => {
                                if (!card.memControlVoltage) card.memControlVoltage = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.memControlVoltage}
                                    key={card.id + 'memVddc'}
                                    setValueFunc={setmemControlVoltageSpecial}
                                    card={card}
                                    min={0}
                                    max={9999}
                                  />
                                )
                              })}
                          </div>
                          {haveAMD1000 && (
                            <>
                              <h5>{t('pages.workers.socFrequency')}</h5>
                              <div className={'gpu-row'}>
                                {profile.overclockingCards
                                  .filter(
                                    card =>
                                      card.cardType === 'amd' &&
                                      (card.gpuName.includes('5300') ||
                                        card.gpuName.includes('5500') ||
                                        card.gpuName.includes('5600') ||
                                        card.gpuName.includes('5700') ||
                                        card.gpuName.includes('6300') ||
                                        card.gpuName.includes('6400') ||
                                        card.gpuName.includes('6500') ||
                                        card.gpuName.includes('6600') ||
                                        card.gpuName.includes('6650') ||
                                        card.gpuName.includes('6700') ||
                                        card.gpuName.includes('6750') ||
                                        card.gpuName.includes('6800') ||
                                        card.gpuName.includes('6900') ||
                                        card.gpuName.includes('6950')),
                                  )
                                  .map(card => {
                                    if (!card.socFrequency) card.socFrequency = ''
                                    return (
                                      <GPUBLockMobile
                                        defaultValue={card.socFrequency}
                                        key={card.id + 'socFrequency'}
                                        setValueFunc={setSocFrequencySpecial}
                                        card={card}
                                        min={0}
                                        max={9999}
                                      />
                                    )
                                  })}
                              </div>
                              <h5>{t('pages.workers.soCVDDMax')}</h5>
                              <div className={'gpu-row'}>
                                {profile.overclockingCards
                                  .filter(
                                    card =>
                                      card.cardType === 'amd' &&
                                      (card.gpuName.includes('5300') ||
                                        card.gpuName.includes('5500') ||
                                        card.gpuName.includes('5600') ||
                                        card.gpuName.includes('5700') ||
                                        card.gpuName.includes('6300') ||
                                        card.gpuName.includes('6400') ||
                                        card.gpuName.includes('6500') ||
                                        card.gpuName.includes('6600') ||
                                        card.gpuName.includes('6650') ||
                                        card.gpuName.includes('6700') ||
                                        card.gpuName.includes('6750') ||
                                        card.gpuName.includes('6800') ||
                                        card.gpuName.includes('6900') ||
                                        card.gpuName.includes('6950')),
                                  )
                                  .map(card => {
                                    if (!card.socVDDmax) card.socVDDmax = ''
                                    return (
                                      <GPUBLockMobile
                                        defaultValue={card.socFrequency}
                                        key={card.id + 'socVDDmax'}
                                        setValueFunc={setsocVDDmaxSpecial}
                                        card={card}
                                        min={0}
                                        max={9999}
                                      />
                                    )
                                  })}
                              </div>
                            </>
                          )}
                          <h5>{t('pages.workers.DPM')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'amd')
                              .map(card => {
                                if (
                                  card.gpuName.includes('5300') ||
                                  card.gpuName.includes('5500') ||
                                  card.gpuName.includes('5600') ||
                                  card.gpuName.includes('5700') ||
                                  card.gpuName.includes('6300') ||
                                  card.gpuName.includes('6400') ||
                                  card.gpuName.includes('6500') ||
                                  card.gpuName.includes('6600') ||
                                  card.gpuName.includes('6650') ||
                                  card.gpuName.includes('6700') ||
                                  card.gpuName.includes('6750') ||
                                  card.gpuName.includes('6800') ||
                                  card.gpuName.includes('6900') ||
                                  card.gpuName.includes('6950')
                                )
                                  card.card1000series = true
                                if (!card.dpm) card.dpm = 1
                                return (
                                  <GPUBLockMobileSelect
                                    defaultValue={card.dpm}
                                    key={card.id + 'dpm'}
                                    setValueFunc={setDPMVddcSpecial}
                                    card={card}
                                    options={
                                      card.card1000series
                                        ? [
                                            { value: 1, label: 1 },
                                            { value: 2, label: 2 },
                                          ]
                                        : [
                                            { value: 1, label: 1 },
                                            { value: 2, label: 2 },
                                            { value: 3, label: 3 },
                                            { value: 4, label: 4 },
                                            { value: 5, label: 5 },
                                            { value: 6, label: 6 },
                                            { value: 7, label: 7 },
                                          ]
                                    }
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.MDPM')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'amd')
                              .map(card => {
                                if (!card.mdpm) card.mdpm = 1
                                return (
                                  <GPUBLockMobileSelect
                                    defaultValue={card.mdpm}
                                    key={card.id + 'mdpm'}
                                    setValueFunc={setMDPMVddcSpecial}
                                    card={card}
                                    options={[
                                      { value: 1, label: 1 },
                                      { value: 2, label: 2 },
                                    ]}
                                  />
                                )
                              })}
                          </div>
                          <h5>{t('pages.workers.FAN')}</h5>
                          <div className={'gpu-row'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'amd')
                              .map(card => {
                                if (!card.fan) card.fan = ''
                                return (
                                  <GPUBLockMobile
                                    defaultValue={card.fan}
                                    key={card.id + 'fan'}
                                    setValueFunc={setFanSpecial}
                                    card={card}
                                    isFan={true}
                                    min={0}
                                    max={100}
                                  />
                                )
                              })}
                          </div>
                        </div>
                      ) : null}
                    </Form>
                    <Form className={'d-none d-md-block overclocking-advanced'}>
                      <div className={'description'}>{t('pages.workers.individualSettingsForEachCard')}</div>
                      {differentManufacturer ? (
                        <div className={'selectManufacturer'}>
                          {t('pages.workers.AMDNVIDIACardsDetected')}
                          <br />
                          <Switch
                            uncheckedIcon={
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                                  fontSize: 10,
                                  color: '#fff',
                                  paddingRight: 10,
                                }}
                              >
                                {' '}
                                NVIDIA
                              </div>
                            }
                            checkedIcon={
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '100%',
                                  fontSize: 10,
                                  color: '#fff',
                                  paddingLeft: 10,
                                }}
                              >
                                {' '}
                                AMD
                              </div>
                            }
                            onColor='#ff0000'
                            offColor='#008000'
                            onChange={() => {
                              setManufacturer(!manufacturer)
                            }}
                            checked={manufacturer}
                            height={20}
                            width={60}
                          />
                        </div>
                      ) : null}
                      {profile &&
                      profile.overclockingCards &&
                      profile.overclockingCards.filter(card => card.cardType === 'nvidia') &&
                      profile.overclockingCards.filter(card => card.cardType === 'nvidia').length > 0 &&
                      (!differentManufacturer || (differentManufacturer && !manufacturer)) ? (
                        <div>
                          <Row className={'hide-on-mobile'}>
                            <Col sm={12} md={3} className={'form-group gpu_info first'}></Col>
                            <Col sm={12} md={9}>
                              <Row className={'advanced_card_row'}>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.coreClock')}</Label>
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.memoryClock')}</Label>
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.powerLimit')}</Label>
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>{t('pages.workers.FAN')}</Label>
                                </div>
                              </Row>
                            </Col>
                          </Row>
                          <div className={'detailed_cards'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType === 'nvidia')
                              .map((card, key) => {
                                if (!card.gpuClock) card.gpuClock = ''
                                if (!card.memClock) card.memClock = ''
                                if (!card.powerLimit) card.powerLimit = ''
                                if (!card.fan) card.fan = ''
                                if (!card.coreVddc) card.coreVddc = ''
                                if (!card.memVddc) card.memVddc = ''
                                if (!card.dpm) card.dpm = 1
                                if (!card.mdpm) card.mdpm = 1

                                return (
                                  <Row key={key}>
                                    <Col sm={12} md={3} className={'form-group gpu_info first'}>
                                      <div className={'gpu_number'}>
                                        GPU{card.cardNum} - {card.cardSlot ? card.cardSlot.substring(5) : ''} -{' '}
                                        <span className={'gpu_name'}>
                                          <span style={{ color: '#949fff' }}>
                                            {card.vendorName && card.vendorName !== 'null' ? card.vendorName : ''}{' '}
                                          </span>
                                          {card.gpuName.replace(/NVIDIA/g, '')}
                                        </span>
                                      </div>
                                      <div className={'gpu_memory'}>
                                        {!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}
                                        {card.memInfo && <span className='card__memType'> • {card.memInfo}</span>}
                                        {card.memType && <span className='card__memType'> {card.memType}</span>}
                                        {!(card.memInfo || card.memType) &&
                                          card.cardType === 'nvidia' &&
                                          card.DefPwrLimit && (
                                            <span className='card__power'>
                                              {' • '}
                                              {card.nvGpuPwrMin}W / {card.DefPwrLimit}W / {card.nvGpuPwrMax}W
                                            </span>
                                          )}
                                      </div>
                                      {card.memInfo && card.memType && (
                                        <div className={'gpu_memory'}>
                                          {card.cardType === 'nvidia' && card.DefPwrLimit && (
                                            <span className='card__power'>
                                              {card.nvGpuPwrMin}W / {card.DefPwrLimit}W / {card.nvGpuPwrMax}W
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </Col>
                                    <Col sm={12} md={9}>
                                      <Row className={'advanced_card_row'}>
                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.coreClock')}</Label>
                                          <RangeSliderSpecial
                                            default={card.gpuClock}
                                            setValue={setCoreClockSpecial}
                                            min={-9999}
                                            max={9999}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'gpuClock')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'gpuClock')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.memoryClock')}</Label>
                                          <RangeSliderSpecial
                                            default={card.memClock}
                                            setValue={setMemoryClockSpecial}
                                            min={-9999}
                                            max={9999}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1 + profile.overclockingCards.length}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'memClock')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'memClock')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>

                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.powerLimit')}</Label>
                                          <RangeSliderSpecial
                                            default={card.powerLimit}
                                            setValue={setPowerLimitSpecial}
                                            min={0}
                                            max={999}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1 + profile.overclockingCards.length * 2}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'powerLimit')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'powerLimit')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>

                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.FAN')}</Label>
                                          <RangeSliderSpecial
                                            default={card.fan}
                                            isFan={true}
                                            setValue={setFanSpecial}
                                            min={0}
                                            max={100}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1 + profile.overclockingCards.length * 4}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'fan')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'fan')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>
                                      </Row>
                                    </Col>
                                    <hr />
                                  </Row>
                                )
                              })}
                          </div>
                        </div>
                      ) : null}
                      {profile &&
                      profile.overclockingCards &&
                      profile.overclockingCards.filter(card => card.cardType === 'amd') &&
                      profile.overclockingCards.filter(card => card.cardType === 'amd').length > 0 &&
                      (!differentManufacturer || (differentManufacturer && manufacturer)) ? (
                        <div>
                          <Row className={'hide-on-mobile'}>
                            <Col sm={12} md={3} className={'form-group gpu_info first'}></Col>
                            <Col sm={12} md={9}>
                              <Row className={'advanced_card_row'}>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>
                                    <Trans i18nKey='pages.workers.coreClockBR' ns={'common'}>
                                      Core Clock,
                                      <br />
                                      Mhz
                                    </Trans>
                                  </Label>
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>
                                    <Trans i18nKey='pages.workers.memoryClockBR' ns={'common'}>
                                      Memory Clock,
                                      <br />
                                      Mhz
                                    </Trans>
                                  </Label>
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>
                                    <Trans i18nKey='pages.workers.coreVDDCBR' ns={'common'}>
                                      coreVDDC,
                                      <br />
                                      mV
                                    </Trans>
                                  </Label>
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>
                                    <Trans i18nKey='pages.workers.memoryVDDCBR' ns={'common'}>
                                      memVDDC,
                                      <br />
                                      mV
                                    </Trans>
                                  </Label>
                                </div>
                                <div className={'form-group advanced_card_field'}>
                                  <Label>
                                    <Trans i18nKey='pages.workers.memoryControllerBR' ns={'common'}>
                                      mem Contrl,
                                      <br />
                                      mV
                                    </Trans>
                                  </Label>
                                </div>
                                {haveAMD1000 ? (
                                  <div className={'form-group advanced_card_field'}>
                                    <Label>
                                      <Trans i18nKey='pages.workers.socFrequencyBR' ns={'common'}>
                                        SoC Frequency,
                                        <br />
                                        Mhz
                                      </Trans>
                                    </Label>
                                  </div>
                                ) : null}
                                {haveAMD1000 ? (
                                  <div className={'form-group advanced_card_field'}>
                                    <Label>
                                      <Trans i18nKey='pages.workers.soCVDDMaxBR' ns={'common'}>
                                        Soc VDDMax,
                                        <br />
                                        mV
                                      </Trans>
                                    </Label>
                                  </div>
                                ) : null}

                                <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                  <Label>{t('pages.workers.FAN')}</Label>
                                </div>
                                <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                  <Label>{t('pages.workers.DPM')}</Label>
                                </div>
                                <div className={'form-group advanced_card_field  advanced_card_field__select'}>
                                  <Label>{t('pages.workers.MDPM')}</Label>
                                </div>
                              </Row>
                            </Col>
                          </Row>
                          <div className={'detailed_cards'}>
                            {profile.overclockingCards
                              .filter(card => card.cardType !== 'nvidia')
                              .map((card, key) => {
                                if (!card.gpuClock) card.gpuClock = ''
                                if (!card.memClock) card.memClock = ''
                                if (!card.powerLimit) card.powerLimit = ''
                                if (!card.fan) card.fan = ''
                                if (!card.coreVddc) card.coreVddc = ''
                                if (!card.memVddc) card.memVddc = ''
                                if (!card.memControlVoltage) card.memControlVoltage = ''
                                if (!card.socFrequency) card.socFrequency = ''
                                if (!card.socVDDmax) card.socVDDmax = ''
                                if (!card.dpm) card.dpm = 1
                                if (!card.mdpm) card.mdpm = 1
                                let card1000series = false
                                if (
                                  card.gpuName.includes('5300') ||
                                  card.gpuName.includes('5500') ||
                                  card.gpuName.includes('5600') ||
                                  card.gpuName.includes('5700') ||
                                  card.gpuName.includes('6300') ||
                                  card.gpuName.includes('6400') ||
                                  card.gpuName.includes('6500') ||
                                  card.gpuName.includes('6600') ||
                                  card.gpuName.includes('6650') ||
                                  card.gpuName.includes('6700') ||
                                  card.gpuName.includes('6750') ||
                                  card.gpuName.includes('6800') ||
                                  card.gpuName.includes('6900') ||
                                  card.gpuName.includes('6950')
                                )
                                  card1000series = true
                                return (
                                  <Row key={key}>
                                    <Col sm={12} md={3} className={'form-group gpu_info first'}>
                                      <div className={'gpu_number'}>
                                        GPU{card.cardNum} - {card.cardSlot ? card.cardSlot.substring(5) : ''} -
                                        <span className={'gpu_name'} style={{ color: '#e4909c' }}>
                                          <span style={{ color: '#949fff' }}>
                                            {card.vendorName && card.vendorName !== 'null' ? card.vendorName : ''}{' '}
                                          </span>
                                          {card.gpuName.replace(/AMD/g, '')}
                                        </span>
                                      </div>
                                      <div className={'gpu_memory'}>
                                        {!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}
                                        {card.memInfo && card.memInfo !== 'null' ? (
                                          <span className='card__memInfo card__power'>{' - ' + card.memInfo}</span>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col sm={12} md={9}>
                                      <Row className={'advanced_card_row'}>
                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.coreClock')}</Label>
                                          <RangeSliderSpecial
                                            default={card.gpuClock}
                                            setValue={setCoreClockSpecial}
                                            min={-9999}
                                            max={9999}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'gpuClock')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'gpuClock')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.memoryClock')}</Label>
                                          <RangeSliderSpecial
                                            default={card.memClock}
                                            setValue={setMemoryClockSpecial}
                                            min={-9999}
                                            max={9999}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1 + profile.overclockingCards.length}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'memClock')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'memClock')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.coreVDDC')}</Label>
                                          <RangeSliderSpecial
                                            default={card.coreVddc}
                                            setValue={setCoreVddcSpecial}
                                            min={0}
                                            max={9999}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1 + profile.overclockingCards.length * 2}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'coreVddc')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'coreVddc')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.memoryVDDC')}</Label>
                                          <RangeSliderSpecial
                                            default={card.memVddc}
                                            setValue={setMemVddcSpecial}
                                            min={0}
                                            max={9999}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1 + profile.overclockingCards.length * 3}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'memVddc')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'memVddc')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>

                                        <div className={'form-group advanced_card_field'}>
                                          <Label className={'show-on-mobile'}>
                                            {t('pages.workers.memoryController')}
                                          </Label>
                                          <RangeSliderSpecial
                                            default={card.memControlVoltage}
                                            setValue={setmemControlVoltageSpecial}
                                            min={0}
                                            max={9999}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1 + profile.overclockingCards.length * 4}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'memControlVoltage')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'memControlVoltage')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>

                                        {haveAMD1000 ? (
                                          <div
                                            className={
                                              card1000series
                                                ? 'form-group advanced_card_field'
                                                : 'form-group advanced_card_field hide-on-mobile'
                                            }
                                          >
                                            {card1000series ? (
                                              <>
                                                <Label className={'show-on-mobile'}>
                                                  {t('pages.workers.socFrequency')}
                                                </Label>
                                                <RangeSliderSpecial
                                                  default={card.socFrequency}
                                                  setValue={setSocFrequencySpecial}
                                                  min={0}
                                                  max={9999}
                                                  step={1}
                                                  card={card}
                                                  tabindex={key + +1 + profile.overclockingCards.length * 5}
                                                />
                                                <div className={'advanced_card_field__arrows'}>
                                                  <div
                                                    className={'advanced_card_field__arrows_arrow'}
                                                    onClick={e => doubleCardUp(e, card, key, 'socFrequency')}
                                                  >
                                                    <i className='fas fa-chevron-double-up'></i>
                                                  </div>
                                                  <div
                                                    className={'advanced_card_field__arrows_arrow'}
                                                    onClick={e => doubleCardDown(e, card, key, 'socFrequency')}
                                                  >
                                                    <i className='fas fa-chevron-double-down'></i>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                          </div>
                                        ) : null}
                                        {haveAMD1000 ? (
                                          <div
                                            className={
                                              card1000series
                                                ? 'form-group advanced_card_field'
                                                : 'form-group advanced_card_field hide-on-mobile'
                                            }
                                          >
                                            {card1000series ? (
                                              <>
                                                <Label className={'show-on-mobile'}>
                                                  {t('pages.workers.soCVDDMax')}
                                                </Label>
                                                <RangeSliderSpecial
                                                  default={card.socVDDmax}
                                                  setValue={setsocVDDmaxSpecial}
                                                  min={0}
                                                  max={9999}
                                                  step={1}
                                                  card={card}
                                                  tabindex={key + +1 + profile.overclockingCards.length * 6}
                                                />
                                                <div className={'advanced_card_field__arrows'}>
                                                  <div
                                                    className={'advanced_card_field__arrows_arrow'}
                                                    onClick={e => doubleCardUp(e, card, key, 'socVDDmax')}
                                                  >
                                                    <i className='fas fa-chevron-double-up'></i>
                                                  </div>
                                                  <div
                                                    className={'advanced_card_field__arrows_arrow'}
                                                    onClick={e => doubleCardDown(e, card, key, 'socVDDmax')}
                                                  >
                                                    <i className='fas fa-chevron-double-down'></i>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                          </div>
                                        ) : null}

                                        <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.FAN')}</Label>
                                          <RangeSliderSpecial
                                            default={card.fan}
                                            setValue={setFanSpecial}
                                            isFan={true}
                                            min={0}
                                            max={100}
                                            step={1}
                                            card={card}
                                            tabindex={key + +1 + profile.overclockingCards.length * 7}
                                          />
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'fan')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'fan')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.DPM')}</Label>
                                          <select
                                            onChange={event => setDPMVddcSpecial(event, card)}
                                            value={card.dpm}
                                            tabIndex={key + +1 + profile.overclockingCards.length * 8}
                                            className={'form-control'}
                                          >
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            {!card1000series && (
                                              <>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                              </>
                                            )}
                                          </select>
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'dpm')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'dpm')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={'form-group advanced_card_field advanced_card_field__select'}>
                                          <Label className={'show-on-mobile'}>{t('pages.workers.MDPM')}</Label>
                                          <select
                                            className={'form-control'}
                                            onChange={event => setMDPMVddcSpecial(event, card)}
                                            value={card.mdpm}
                                            tabIndex={key + +1 + profile.overclockingCards.length * 9}
                                          >
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                          </select>
                                          <div className={'advanced_card_field__arrows'}>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardUp(e, card, key, 'mdpm')}
                                            >
                                              <i className='fas fa-chevron-double-up'></i>
                                            </div>
                                            <div
                                              className={'advanced_card_field__arrows_arrow'}
                                              onClick={e => doubleCardDown(e, card, key, 'mdpm')}
                                            >
                                              <i className='fas fa-chevron-double-down'></i>
                                            </div>
                                          </div>
                                        </div>
                                      </Row>
                                    </Col>
                                    <hr />
                                  </Row>
                                )
                              })}
                          </div>
                        </div>
                      ) : null}
                    </Form>
                  </TabPanel>
                )}
                <TabPanel>
                  <Form className={'overclocking-additional'}>
                    <div className={'description'}>{t('pages.workers.additionalOverclockingParameters')}</div>
                    <div>
                      {profile &&
                      ((profile.overclockingCards &&
                        profile.overclockingCards.filter(card => card.cardType === 'amd') &&
                        profile.overclockingCards.filter(card => card.cardType === 'amd').length > 0) ||
                        profile.cardType === 'amd') ? (
                        <div className={'cardTweak'}>
                          <Label className={'checkboxLabel'}>
                            <input
                              name={'rotateLogs'}
                              type={'checkbox'}
                              checked={amdmemorytweak}
                              onChange={() => {
                                setAmdmemorytweak(!amdmemorytweak)
                                if (amdmemorytweak) profile.amdmemorytweak = null
                              }}
                            />
                            {t('pages.workers.amdmemorytweak')}
                          </Label>
                          <Button
                            color={'primary'}
                            disabled={!amdmemorytweak}
                            onClick={() => {
                              setAmdmemorytweakEdit(true)
                            }}
                          >
                            {t('pages.workers.edit')}
                          </Button>
                        </div>
                      ) : null}
                      {profile &&
                      ((profile.overclockingCards &&
                        profile.overclockingCards.filter(
                          card => card.cardType === 'nvidia' && card.gpuName.includes('1080'),
                        ) &&
                        profile.overclockingCards.filter(
                          card => card.cardType === 'nvidia' && card.gpuName.includes('1080'),
                        ).length > 0) ||
                        (profile.gpuName && profile.gpuName.includes('1080'))) ? (
                        <div className={'cardTweak'}>
                          <Label className={'checkboxLabel'}>
                            <input
                              name={'rotateLogs'}
                              type={'checkbox'}
                              checked={ohGodAnETHlagementPill}
                              onChange={() => {
                                setOhGodAnETHlagementPill(!ohGodAnETHlagementPill)
                                if (ohGodAnETHlagementPill) profile.onGodAnEthArgPill = null
                              }}
                            />
                            {t('pages.workers.OhGodAnETHlagementPill')}
                          </Label>
                          <Button
                            color={'primary'}
                            disabled={!ohGodAnETHlagementPill}
                            onClick={() => {
                              setOhGodAnETHlagementPillEdit(true)
                            }}
                          >
                            {t('pages.workers.edit')}
                          </Button>
                        </div>
                      ) : null}

                      {profile &&
                      ((profile.overclockingCards &&
                        profile.overclockingCards.filter(card => card.cardType === 'nvidia') &&
                        profile.overclockingCards.filter(card => card.cardType === 'nvidia').length > 0) ||
                        profile.cardType === 'nvidia') ? (
                        <div className={'cardTweak'}>
                          <Label className={'checkboxLabel'}>
                            <input
                              name={'p0'}
                              type={'checkbox'}
                              checked={p0}
                              onChange={() => {
                                setP0(!p0)
                                if (p0) profile.p0 = null
                              }}
                            />
                            {t('pages.workers.P0mode')}
                          </Label>
                        </div>
                      ) : null}

                      <div className={'cardTweak'}>
                        <Label className={'checkboxLabel'}>
                          <input
                            name={'rotateLogs'}
                            type={'checkbox'}
                            checked={autoTune}
                            onChange={() => {
                              setAutoTune(!autoTune)
                              if (autoTune) profile.autoTune = null
                            }}
                          />
                          {t('pages.workers.autoTune')}
                        </Label>
                        <Button
                          color={'primary'}
                          disabled={!autoTune}
                          onClick={() => {
                            setAutoTuneEdit(true)
                          }}
                        >
                          {t('pages.workers.edit')}
                        </Button>
                      </div>
                    </div>
                    <Row className={'card_algorithm'}>
                      <Col xs={12} sm={4} md={3}>
                        <Label>{t('pages.workers.algorithm')}</Label>
                        <SelectWithCryptoImages
                          name={'algo'}
                          placeholder={t('inputs.selectAlgo')}
                          hideSelectedOptions
                          classNamePrefix={'form_control_react_select'}
                          onchange={setAlgo}
                          value={algorithm}
                          options={props.algoCoinsList.map(algo => {
                            return {
                              ...algo,
                              label: algo.algo,
                              value: algo.algo,
                            }
                          })}
                        />
                      </Col>
                    </Row>
                    <div
                      className={'cardTweakString'}
                      style={{
                        height: amdmemorytweakEdit && amdmemorytweak ? '170px' : '0',
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                          <textarea
                            className='form-control'
                            name='amdmemorytweak'
                            value={amdmemorytweakString2}
                            onChange={e => {
                              setAmdmemorytweakString2(e.target.value)
                            }}
                          ></textarea>
                        </Col>
                        <Col xs={12} className={'cardTweakString__Buttons'}>
                          <Button color='primary' onClick={saveAmdmemorytweak}>
                            {t('pages.workers.save')}
                          </Button>
                          <Button color='secondary' onClick={resetAmdmemorytweak}>
                            {t('pages.workers.cancel')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className={'cardTweakString'}
                      style={{
                        height: ohGodAnETHlagementPillEdit && ohGodAnETHlagementPill ? '170px' : '0',
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                          <textarea
                            className='form-control'
                            name='ohGodAnETHlagementPilltweak'
                            value={ohGodAnETHlagementPillString2}
                            onChange={e => {
                              setOhGodAnETHlagementPillString2(e.target.value)
                            }}
                          ></textarea>
                        </Col>
                        <Col xs={12} className={'cardTweakString__Buttons'}>
                          <Button color='primary' onClick={saveOhGodAnETHlagementPill}>
                            {t('pages.workers.save')}
                          </Button>
                          <Button color='secondary' onClick={resetOhGodAnETHlagementPill}>
                            {t('pages.workers.cancel')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <div
                      className={'cardTweakString'}
                      style={{
                        height: autoTuneEdit && autoTune ? '170px' : '0',
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <h6>{t('pages.workers.EditCcommandLineParameters')}</h6>
                          <textarea
                            className='form-control'
                            name='ohGodAnETHlagementPilltweak'
                            value={autoTuneEditString2}
                            onChange={e => {
                              setAutoTuneEditString2(e.target.value)
                            }}
                          ></textarea>
                        </Col>
                        <Col xs={12} className={'cardTweakString__Buttons'}>
                          <Button color='primary' onClick={saveAutoTune}>
                            {t('pages.workers.save')}
                          </Button>
                          <Button color='secondary' onClick={resetAutoTune}>
                            {t('pages.workers.cancel')}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </TabPanel>
              </Tabs>
              <div className={'actions_card'}>
                <Row>
                  <Col sm={4} className={'radio_buttons'}>
                    <div>
                      <input
                        type='radio'
                        id='apply_action4'
                        name='apply_action2'
                        value='on_fly'
                        onChange={e => setApplyAction(e)}
                        checked={action === 'on_fly'}
                      />
                      <Label htmlFor='apply_action4'>{t('pages.workers.applyFly')}</Label>
                    </div>
                    <div>
                      <input
                        type='radio'
                        id='apply_action5'
                        name='apply_action2'
                        value='restart'
                        onChange={e => setApplyAction(e)}
                        checked={action === 'restart'}
                      />
                      <Label htmlFor='apply_action5'>{t('pages.workers.restartAndApply')}</Label>
                    </div>
                    <div>
                      <input
                        type='radio'
                        id='apply_action6'
                        name='apply_action2'
                        value='reboot'
                        onChange={e => setApplyAction(e)}
                        checked={action === 'reboot'}
                      />
                      <Label htmlFor='apply_action6'>{t('pages.workers.rebootAndApply')}</Label>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {profile && (
            <>
              <Button disabled={!profile} color='danger' onClick={() => setShowAlertDelete(true)}>
                {t('pages.overclocking.delete')}
              </Button>
              <Dropdown
                isOpen={showSaveActions}
                toggle={() => setShowSaveActions(!showSaveActions)}
                direction={'up'}
                className={'d-md-none'}
              >
                <DropdownToggle color='success'> {t('pages.workers.saveActions')}</DropdownToggle>
                <DropdownMenu flip={true}>
                  <DropdownItem
                    onClick={() => checkOverclockingErrors(toggleUpdateDialog, setShowCardsErrorAlertProfileUpdate)}
                  >
                    {t('pages.overclocking.save')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => checkOverclockingErrors(saveAndApply, setShowCardsErrorAlertProfileUpdateApply)}
                  >
                    {t('pages.workers.loadApply')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => checkOverclockingErrors(toggleSaveDialog, setShowCardsErrorAlertProfileSave)}
                  >
                    {t('pages.workers.saveAs')}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Button
                disabled={!profile}
                className={'d-none d-md-block'}
                color='success'
                onClick={() => checkOverclockingErrors(toggleUpdateDialog, setShowCardsErrorAlertProfileUpdate)}
              >
                {t('pages.overclocking.save')}
              </Button>
              <Button
                disabled={!profile}
                color='primary'
                className={'d-none d-md-block'}
                onClick={() => checkOverclockingErrors(saveAndApply, setShowCardsErrorAlertProfileUpdateApply)}
              >
                {t('pages.workers.loadApply')}
              </Button>

              <Button
                disabled={!profile}
                color='success'
                className={'d-none d-md-block'}
                onClick={() => checkOverclockingErrors(toggleSaveDialog, setShowCardsErrorAlertProfileSave)}
              >
                {t('pages.workers.saveAs')}
              </Button>
            </>
          )}
          <Button color='secondary' onClick={props.toggle}>
            {t('pages.workers.cancel')}
          </Button>
        </ModalFooter>
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  rigsList: store.rigs.rigsList,
  algoCoinsList: store.profiles.algoCoinsList,
  overclockingProfilesList: store.profiles.overclockingProfilesList,
})

const mapDispatchToProps = {
  overclocking: actions.overclocking,
  overclockingEach: actions.overclockingEach,
  saveOverclockingProfile: actionsProfiles.saveOverclockingProfile,
  setOverclockingProfilesList: actionsProfiles.setOverclockingProfilesList,
  updateOverclockingProfile: actionsProfiles.updateOverclockingProfile,
  applyOverclockingProfile: actionsProfiles.applyOverclockingProfile,
  deleteProfile: actionsProfiles.deleteProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverclockingProfileTab)
