import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

// falgs
import usFlag from '../assets/images/flags/us_flag.jpg'
import russiaFlag from '../assets/images/flags/russia_flag.jpg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
const LanguageDropdown = () => {
  const [menu, setMenu] = useState(false)
  const { i18n } = useTranslation('common')
  const toggle = () => {
    setMenu(!menu)
  }
  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className='ml-2 language-dropdown'>
        <DropdownToggle className='btn header-item waves-effecte' tag='button'>
          <img className='mr-2' src={i18n.language === 'en' ? usFlag : russiaFlag} alt='Header Language' height='16' />{' '}
          <span className={'d-none d-md-inline-block'}>{i18n.language === 'en' ? 'English' : 'Русский'}</span>
          <span className='d-none d-md-inline-block mdi mdi-chevron-down'></span>
        </DropdownToggle>
        <DropdownMenu className='language-switch' right>
          <DropdownItem
            tag='a'
            href='#'
            className='notify-item'
            onClick={() => {
              i18n.changeLanguage('en')
              Cookies.set('lang', JSON.stringify('en'), {
                expires: 30,
                sameSite: 'Lax',
                secure: true,
              })
            }}
          >
            <img src={usFlag} alt='amos' className='mr-1' height='12' /> <span className='align-middle'> English </span>
          </DropdownItem>
          <DropdownItem
            tag='a'
            href='#'
            className='notify-item'
            onClick={() => {
              i18n.changeLanguage('ru')
              Cookies.set('lang', JSON.stringify('ru'), {
                expires: 30,
                sameSite: 'Lax',
                secure: true,
              })
            }}
          >
            <img src={russiaFlag} alt='amos' className='mr-1' height='12' />{' '}
            <span className='align-middle'> Russian </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default LanguageDropdown
