import React, { useEffect, useRef, useState } from 'react'
import { Input } from 'reactstrap'

const GPUBLockMobile = ({
  card,
  setValueFunc,
  defaultValue,
  isFan = false,
  min = 0,
  max = 9999,
  step = 1,
  onClick,
  disableDoubleButtons,
}) => {
  const [showInput, setShowInput] = useState(false)
  const [valOld, setValOld] = useState(defaultValue)
  const blockRef = useRef(null)
  const inputRef = useRef(null)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          showInput &&
          ref &&
          ref.current &&
          !ref.current.contains(event.target) &&
          !(event.target?.className.toString() && event.target.className.toString().includes('doubleArrow'))
        ) {
          disableDoubleButtons()
          setShowInput(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref, showInput])
  }
  useOutsideAlerter(blockRef)
  return (
    <div
      ref={blockRef}
      className={showInput ? 'gpu-block active' : 'gpu-block'}
      onClick={() => {
        setShowInput(true)
        setTimeout(() => {
          inputRef.current && inputRef.current.focus()
        }, 100)
        onClick()
      }}
    >
      {!showInput && (
        <>
          GPU{card.detected ? card.cardNum : ''}
          <span>{defaultValue}</span>
        </>
      )}
      <Input
        className={showInput ? '' : 'd-none'}
        innerRef={inputRef}
        onChange={e => {
          setValueFunc(e.target.value, card)
          if (!isNaN(parseFloat(e.target.value))) {
            setValOld(parseFloat(e.target.value))
          }
        }}
        onBlur={e => {
          let s = e.target.value
          if (parseFloat(s) === 0) {
            setValOld('0')
            setValueFunc('0', card)
          } else {
            if (s.length === 0) {
              s = '0'
              if (isFan) s = '60'
              setValOld(s)
              setValueFunc(s, card)
            } else if (!isNaN(parseFloat(s))) {
              s = parseFloat(s)
              if (s < min) s = min.toString()
              if (s > max) s = max.toString()
              setValueFunc(s, card)
              setValOld(s)
            } else {
              setValueFunc(valOld, card)
            }
          }
        }}
        value={defaultValue}
        step={step}
        min={min}
        max={max}
      />
      {showInput && (
        <div className={'gpu-description'}>
          <div className={'gpu_number'}>
            GPU{card.detected ? card.cardNum : ''} -{' '}
            <span
              className={'gpu_name'}
              style={{ color: card.cardType.toLowerCase() === 'amd' ? '#e4909c' : '#01b95d' }}
            >
              <span style={{ color: '#949fff' }}>
                {card.vendorName && card.vendorName !== 'null' ? card.vendorName : ''}{' '}
              </span>
              {card.gpuName.replace(/NVIDIA/g, '').replace(/AMD/g, '')}
            </span>
          </div>
          <div className={'gpu_memory'}>
            {!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}
            {card.memInfo && <span className='card__memType'> • {card.memInfo}</span>}
            {card.memType && <span className='card__memType'> {card.memType}</span>}
            {/*{!(card.memInfo || card.memType) &&
                      card.cardType === 'nvidia' &&
                      card.DefPwrLimit && (
                          <span className="card__power">
                                              {' • '}
                              {card.nvGpuPwrMin}W /{' '}
                              {card.DefPwrLimit}W /{' '}
                              {card.nvGpuPwrMax}W
                                            </span>
                      )}
                  {card.memInfo && card.memType &&card.cardType === 'nvidia' &&
                      card.DefPwrLimit && (
                          <span className="card__power">
                                                {card.nvGpuPwrMin}W /{' '}
                              {card.DefPwrLimit}W /{' '}
                              {card.nvGpuPwrMax}W
                                              </span>
                      )}*/}
          </div>
        </div>
      )}
    </div>
  )
}

export default GPUBLockMobile
