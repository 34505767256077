import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Input, Button } from 'reactstrap'
import { Modal } from 'components'
import * as actions from '../../../../RigsData/actions'
import { useTranslation } from 'react-i18next'

const Settings = props => {
  const [errors, setErrors] = useState({})
  const { t } = useTranslation('common')
  const [minerPresetId, setMinerPresetId] = useState()
  const [workerUpdate, setWorkerUpdate] = useState(false)
  const [minerUpdate, setMinerUpdate] = useState(false)
  const [workerSettings, setWorkerSettings] = useState(false)
  const [showAlertRename, setShowAlertRename] = useState(false)
  const [workerData, setWorkerData] = useState(false)
  const [formElements, setFormElements] = useState({})

  const updForm = useRef()

  const [width, setWidth] = useState(document.body.offsetWidth)
  const handleWindowSizeChange = () => {
    setWidth(document.body.offsetWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 767

  const updatePass = async () => {
    let isValid = true

    if (!updForm.current.pass.value) {
      isValid = false
      errors.pass = t('pages.workers.emptyField')
    } else {
      delete errors.pass
    }
    if (!updForm.current.updPassCheckbox.checked) {
      isValid = false
      errors.updPassCheckbox = t('pages.workers.shouldChecked')
    } else {
      delete errors.updPassCheckbox
    }
    setErrors({ ...errors })

    if (isValid) {
      alert(t('pages.workers.PassUpdateFunction'))
    }
  }

  const onChangeCheckBox = e => {
    if (!e.target.checked) {
      errors.updPassCheckbox = t('pages.workers.shouldChecked')
    } else {
      delete errors.updPassCheckbox
    }
    setErrors({ ...errors })
  }

  const onChange = e => {
    if (
      [
        'workerName',
        'pass',
        'description',
        'updateInterval',
        'maintenanceMode',
        'powerCycle',
        'minerStartDelay',
        'lowHashScope',
        'critHashScope',
        'powerConsumption',
        'psuEfficiency',
      ].includes(e.target.name)
    ) {
      console.log('in onChange')
      const value = e.target.value.trim()
      if (!value) {
        errors[e.target.name] = t('pages.workers.emptyField')
      } else {
        delete errors[e.target.name]
      }

      if (e.target.name === 'workerName' && value !== props.newWorker.name) setWorkerUpdate(true)
      if (e.target.name === 'description' && value !== props.newWorker.description) setWorkerUpdate(true)

      if (props.worker.rigSettings && e.target.name !== 'workerName') {
        if (
          value !== props.worker.rigSettings.updateInterval ||
          value !== props.worker.rigSettings.maintenanceMode ||
          value !== props.worker.rigSettings.powerCycle ||
          value !== props.worker.rigSettings.minerStartDelay ||
          value !== props.worker.rigSettings.lowHashScope ||
          value !== props.worker.rigSettings.critHashScope ||
          value !== props.worker.rigSettings.powerConsumption ||
          value !== props.worker.rigSettings.psuEfficiency
        )
          setWorkerSettings(true)
      } else {
        if (
          e.target.name === 'updateInterval' ||
          e.target.name === 'maintenance' ||
          e.target.name === 'cycle' ||
          e.target.name === 'delay' ||
          e.target.name === 'lowHash' ||
          e.target.name === 'critHash' ||
          e.target.name === 'consumption' ||
          e.target.name === 'efficiency'
        )
          setWorkerSettings(true)
      }

      setErrors({ ...errors })
    }
  }

  const selectMiningPreset = async event => {
    setMinerPresetId(event.target.value)
    if (props.worker.miningPresetId !== event.target.value) setMinerUpdate(true)
  }

  const handlerFormSubmit = async event => {
    event.preventDefault()

    const formElementsSelected = event.currentTarget.elements
    setFormElements(formElementsSelected)

    const workerInput = {
      id: +props.worker.id,
      name: formElementsSelected.workerName.value,
      description: formElementsSelected.description.value,
    }

    let isValid = true

    Object.keys(workerInput).forEach(key => {
      if (
        ![
          'description',
          'updateInterval',
          'maintenanceMode',
          'powerCycle',
          'minerStartDelay',
          'lowHashScope',
          'critHashScope',
          'powerConsumption',
          'psuEfficiency',
        ].includes(key)
      ) {
        if (!workerInput[key]) {
          isValid = false
          errors[key] = t('pages.workers.emptyField')
        } else {
          delete errors[key]
        }
      }
    })

    setErrors({ ...errors })

    if (isValid) {
      if (workerUpdate) {
        setShowAlertRename(true)
        setWorkerData(workerInput)
      } else {
        await props.updateRigSettings({
          rigId: +props.worker.id,
          miningPresetId: minerUpdate ? minerPresetId : props.worker.miningPresetId,
          name: workerData.name ? workerData.name : props.worker.name,
          description: workerData.description ? workerData.description : props.worker.description,
          action: 'run',
          updateInterval:
            workerSettings && formElementsSelected.updateInterval
              ? formElementsSelected.updateInterval.value
              : props.worker.rigSettings
              ? props.worker.rigSettings.updateInterval
              : '5 sec',
          maintenanceMode:
            workerSettings && formElementsSelected.maintenance ? formElementsSelected.maintenance.value.toString() : '',
          powerCycle: workerSettings && formElementsSelected.delay ? formElementsSelected.delay.value : '',
          lowHashScope: workerSettings && formElementsSelected.lowHash ? +formElementsSelected.lowHash.value : 0,
          critHashScope: workerSettings && formElementsSelected.critHash ? +formElementsSelected.critHash.value : 0,
          powerConsumption:
            workerSettings && formElementsSelected.consumption ? +formElementsSelected.consumption.value : 0,
          psuEfficiency:
            workerSettings && formElementsSelected.efficiency ? formElementsSelected.efficiency.value.toString() : '',
          workerSettings,
          workerUpdate,
          minerUpdate,
        })

        setWorkerUpdate(false)
        setWorkerSettings(false)
        setMinerUpdate(false)
        setFormElements({})
        props.onCancel()
      }
    }
  }

  const onHandleChangeData = async (action, mpId) => {
    await props.updateRigSettings({
      rigId: +props.worker.id,
      miningPresetId: minerUpdate ? minerPresetId : mpId,
      name: workerData.name,
      description: workerData.description,
      action: 'run',
      reboot: action === 'restart' ? false : true,
      updateInterval: formElements.updateInterval.value,
      maintenanceMode: formElements.maintenance.value,
      powerCycle: formElements.cycle.value,
      minerStartDelay: +formElements.delay.value,
      lowHashScope: +formElements.lowHash.value,
      critHashScope: +formElements.critHash.value,
      powerConsumption: +formElements.consumption.value,
      psuEfficiency: formElements.efficiency.value,
      workerSettings,
      workerUpdate,
      minerUpdate,
    })

    setWorkerUpdate(false)
    setWorkerSettings(false)
    setMinerUpdate(false)
    setFormElements({})
    setShowAlertRename(false)
  }

  return (
    <>
      <Modal
        title={t('pages.workers.changeWorkerName')}
        onFinish={() => onHandleChangeData('reboot', props.worker.miningPresetId)}
        onFinish2={() => onHandleChangeData('restart', props.worker.miningPresetId)}
        finishTitle={t('pages.workers.reboot')}
        finishTitle2={t('pages.workers.restart')}
        isOpen={showAlertRename}
        hideCanselBtn
        className='checklist__ignore-modal'
      >
        {t('pages.workers.rebootWorkerRestartMining')}
      </Modal>

      <form onSubmit={handlerFormSubmit} ref={updForm} className={'worker-setting-form'}>
        <Row className='form-content worker-form_row'>
          <Col className='worker-form__col worker-form__col_bordered'>
            <div className={'form-item worker-form__item worker-form__item_id'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.workerID')}:</p>
              <p className={'form-item__input worker-form__value'}>{props.newWorker.id}</p>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.workerName')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  invalid={!!errors.workerName}
                  type={'text'}
                  onChange={onChange}
                  name={'workerName'}
                  defaultValue={props.newWorker.name}
                  className='worker-item__input-field'
                />
                <div className={'worker-item__error'}>{errors.workerName}</div>
              </div>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.workerPassword')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  invalid={!!errors.pass}
                  type={'text'}
                  onChange={onChange}
                  name={'pass'}
                  defaultValue={props.newWorker.password}
                  className='worker-item__input-field'
                />
                <div className={'worker-item__error'}>{errors.pass}</div>
              </div>
              <Button color='secondary' className='worker-form__btn' onClick={updatePass}>
                {t('pages.workers.updatePassword')}
              </Button>
            </div>
            <div className={'form-item worker-form__item'}>
              {!isMobile && <p className={'worker-form-item__label'}></p>}
              <input
                type='checkbox'
                onChange={onChangeCheckBox}
                className={`${
                  errors.updPassCheckbox
                    ? 'form-item__checkbox_error'
                    : 'form-item__checkbox form-item__checkbox_border'
                }`}
                name={'updPassCheckbox'}
              />
              <p className={'form-item__input worker-form__value_check'}>{t('pages.workers.changePasswordWorker')}</p>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.workerDescription')} :</p>
              <div className={'form-item__input worker-item__area'}>
                <Input
                  type={'textarea'}
                  rows={1}
                  maxLength={100}
                  onChange={onChange}
                  name={'description'}
                  defaultValue={props.worker.description}
                  className='worker-item__input-field'
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'form-content worker-form_row'}>
          <Col className='worker-form__col worker-form__col_bordered'>
            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.overclockingProfile')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  invalid={!!errors.overclocking}
                  type={'select'}
                  onChange={onChange}
                  name={'overclocking'}
                  defaultValue={'Default'}
                  className='worker-item__input-field'
                >
                  <option value='' disabled>
                    {t('pages.workers.selectProfile')}
                  </option>
                  <option>{t('pages.workers.default')} </option>
                  <option>{t('pages.workers.overclockingProfile')}</option>
                </Input>
                <div className={'error'}>{errors.overclocking}</div>
              </div>
              <p className={'worker-form__value'}>
                {t('pages.workers.activeProfile')}: <span>{t('pages.workers.default')}</span>
              </p>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.watchdogProfile')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  type={'select'}
                  onChange={onChange}
                  name={'watchdog'}
                  defaultValue={'None'}
                  className='worker-item__input-field'
                >
                  <option value='' disabled>
                    {t('pages.workers.selectProfile')}
                  </option>
                  <option>{t('pages.workers.none')}</option>
                  <option>{t('pages.workers.default')}</option>
                </Input>
              </div>
              <p className={'worker-form__value'}>
                {t('pages.workers.activeProfile')}: <span>{t('pages.workers.none')}</span>
              </p>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}> {t('pages.workers.miningPreset')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  type={'select'}
                  onChange={selectMiningPreset}
                  name={'miner'}
                  defaultValue={props.worker.miningPresetId ? props.worker.miningPresetId : '0'}
                  className='worker-item__input-field'
                >
                  <option value='0' disabled>
                    {t('pages.workers.selectPreset')}
                  </option>
                  {props.miningPresetList.map((preset, key) => {
                    return (
                      <option value={preset.id} key={key}>
                        {preset.name}
                      </option>
                    )
                  })}
                </Input>
              </div>
              <p className={'worker-form__value'}>
                {t('pages.workers.activePreset')}:
                {props.worker.miningPreset && <span>{props.worker.miningPreset.name}</span>}
              </p>
            </div>
          </Col>
        </Row>

        <Row className={'form-content worker-form_row'}>
          <Col className='worker-form__col worker-form__col_bordered'>
            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.UpdatePushInterval')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  type={'select'}
                  onChange={onChange}
                  name={'updateInterval'}
                  defaultValue={props.worker.rigSettings ? props.worker.rigSettings.updateInterval : '10 sec'}
                  className='worker-item__input-field'
                >
                  <option>10 {t('pages.workers.sec')}</option>
                  <option>30 {t('pages.workers.sec')}</option>
                  <option>60 {t('pages.workers.sec')}</option>
                </Input>
              </div>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.maintenanceMode')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  type={'select'}
                  onChange={onChange}
                  name={'maintenance'}
                  defaultValue={props.worker.rigSettings ? props.worker.rigSettings.maintenanceMode : ''}
                  className='worker-item__input-field'
                >
                  <option>{t('pages.workers.disabled')}</option>
                  <option>{t('pages.workers.enabled')}</option>
                </Input>
              </div>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.powerCycle')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  type={'select'}
                  onChange={onChange}
                  name={'cycle'}
                  defaultValue={props.worker.rigSettings ? props.worker.rigSettings.powerCycle : ''}
                  className='worker-item__input-field'
                >
                  <option>{t('pages.workers.internal')}</option>
                  <option>{t('pages.workers.external')}</option>
                </Input>
              </div>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.minerStartDelay')}:</p>
              <div className={'form-item__input worker-item__input'}>
                <Input
                  type={'text'}
                  onChange={onChange}
                  name={'delay'}
                  defaultValue={props.worker.rigSettings ? props.worker.rigSettings.minerStartDelay : ''}
                  // placeholder='Seconds'
                  className='worker-item__input-field'
                />
              </div>
            </div>
            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label '}>
                {t('pages.workers.markWorkerLHR')}
                <br />
                <span>{t('pages.workers.ifHashrateCardLower')} , %</span>
              </p>
              <div className={'form-item__input worker-item__input worker-item__input_multi'}>
                <Input
                  type={'text'}
                  onChange={onChange}
                  name={'lowHash'}
                  className='worker-item__input-field worker-item__input-field_s'
                  defaultValue={props.worker.rigSettings ? props.worker.rigSettings.lowHashScope : '5'}
                />
                <Input
                  type={'text'}
                  onChange={onChange}
                  name={'critHash'}
                  className='worker-item__input-field worker-item__input-field_s'
                  defaultValue={props.worker.rigSettings ? props.worker.rigSettings.critHashScope : '10'}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className={'form-content worker-form_row'}>
          <Col className='worker-form__col'>
            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.hardwarePowerConsumption')}:</p>
              <div className={'form-item__input worker-item__input_s'}>
                <Input
                  type={'text'}
                  onChange={onChange}
                  name={'consumption'}
                  defaultValue={props.worker.rigSettings ? props.worker.rigSettings.powerConsumption : ''}
                  // placeholder='Watts'
                  className='worker-item__input-field'
                />
              </div>
              <input type='checkbox' className='form-item__checkbox form-item__checkbox_border' name={'autoPower'} />
              <p className={'worker-form__value_check'}>{t('pages.workers.auto')}</p>
            </div>

            <div className={'form-item worker-form__item'}>
              <p className={'worker-form-item__label'}>{t('pages.workers.PSUEfficiency')}, %:</p>
              <div className={'form-item__input worker-item__input_s'}>
                <Input
                  type={'text'}
                  onChange={onChange}
                  name={'efficiency'}
                  defaultValue={props.worker.rigSettings ? props.worker.rigSettings.psuEfficiency : ''}
                  // placeholder='Percents'
                  className='worker-item__input-field'
                />
              </div>
            </div>
          </Col>
        </Row>

        <button type='submit' ref={props.submitRef} style={{ width: 0, height: 0, border: 'none', opacity: 0 }} />
      </form>
    </>
  )
}

const mapStateToProps = store => ({
  miningPresetList: store.presets.miningPresetList,
})

const mapDispatchToProps = {
  updateRigSettings: actions.updateRigSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
